import { axiosApiInstance as axios } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/users";

export const login = async (formData) => {

  return axios.post(serverUrl + "/loginAdmin", formData);
};

export const addUser = (formData) => {
  return axios.post(serverUrl + "/registerOtherUsers", formData);
};

export const getUser = (query) => {
  return axios.get(`${serverUrl}/getUsers?role=USER`);
};

export const updateUserStatus = (id, formData) => {
  return axios.patch(`${serverUrl}/updateUserStatus/${id}`, formData);
};

export const updateUserKycStatus = (id, formData) => {
  return axios.patch(`${serverUrl}/updateUserKycStatus/${id}`, formData);
};

export const deleteUser = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const getSpecificCustomer = (value) => {
  return axios.get(`${serverUrl}/getSpecificCustomer?search=${value}`);
};

export const getById = (id) => {
  return axios.get(`${serverUrl}/getById/${id}`);
};

export const updateUser = (formData, id) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};




export const getToken = () => {
  let token = localStorage.getItem("token")
  return token
}