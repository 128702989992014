import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteClientImage, getClientImage } from "../../services/Clients.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddClients from "./AddClients";
import { generateFilePath } from "../Utility/utils";
export default function ViewClients() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [ourServicesArr, setOurServicesArr] = useState([]);
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");

    const handleGetClientImages = async () => {
        try {
            let { data: res } = await getClientImage();
            if (res.success) {
                console.log(res.data)
                setOurServicesArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteCategory = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deleteClientImage(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetClientImages()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Client Logo 1",
            cell: (row) => <img src={generateFilePath(row.image1)} style={{ height: "100px", width: "100px" }} alt="" />,
        },
        {
            name: "Client Logo 2",
            cell: (row) => <img src={generateFilePath(row.image2)} style={{ height: "100px", width: "100px" }} alt="" />,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        // edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setModalBox(true)
                            setModalType("editSubCategories");
                            setModalName("Edit SubCategories");
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];



    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.overView && el?.overView.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.description && el?.description.toLowerCase().includes(`${value}`.toLowerCase()))
        ))
        setOurServicesArr([...tempArr])
        setQuery(value)
    }


    useEffect(() => {
        handleGetClientImages()
        return () => setOurServicesArr([])
    }, [dataIsAdded])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Add New Clients</h5>
                            </div>
                            <DashboardBox>
                                <AddClients setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Clients Lists</h5>
                                {/* <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" /> */}
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={ourServicesArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
