import React, { useEffect, useState } from 'react'
import { addHomepagebannerValue, updateHomepagebanner } from '../../services/HomepageBanners.service';
import { addSeoValue, updateSeo } from '../../services/seo.service';
import CustomButton from '../Utility/Button';
import FileUpload from '../Utility/FileUpload';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';

export default function AddHomepageBanners({ setDataIsAdded, currentSelectedHomepagebanner, setCurrentSelectedHomepagebanner }) {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [btnText, setBtnText] = useState("");
    const [btnLink, setBtnLink] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");


    const handleFileSet = (value) => {
        // console.log(value);
        setImage(value);
    };


    const handleFileSet1 = (value) => {
        // console.log(value);
        setBackgroundImage(value);
    };



    const handleSubmit = async () => {
        try {
            let obj = {
                title,
                description,
                image,
                btnText,
                btnLink,
                backgroundImage,
            };
            let res
            if (currentSelectedHomepagebanner?.title) {
                res = await updateHomepagebanner(currentSelectedHomepagebanner?._id, obj)
            }
            else {
                res = await addHomepagebannerValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };



    useEffect(() => {
        if (currentSelectedHomepagebanner.title) {
            console.log(currentSelectedHomepagebanner, "currentSelectedSeo")
            setTitle(currentSelectedHomepagebanner?.title)
            setDescription(currentSelectedHomepagebanner?.description)
            setImage(currentSelectedHomepagebanner?.image)
            setBtnText(currentSelectedHomepagebanner?.btnText)
            setBtnLink(currentSelectedHomepagebanner?.btnLink)
            setBackgroundImage(currentSelectedHomepagebanner?.backgroundImage)
        }

    }, [currentSelectedHomepagebanner])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Title<span className="red">*</span>
                </label>
                <input onChange={(event) => setTitle(event.target.value)} value={title} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description<span className="red">*</span>
                </label>
                <input onChange={(event) => setDescription(event.target.value)} value={description} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Button Text <span className="red">*</span>
                </label>
                <input onChange={(event) => setBtnText(event.target.value)} value={btnText} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Button Link <span className="red">*</span>
                </label>
                <input onChange={(event) => setBtnLink(event.target.value)} value={btnLink} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image<span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    image && image.includes("base64") ?
                        <img style={{ width: 200 }} src={image && image != "" ? image.split("@@")[1] : ""} alt="" />
                        :
                        <img style={{ width: 200 }} src={generateFilePath(image)} alt="" srcset="" />
                }

                <br />
                <br />
                <br />

                <FileUpload onFileChange={handleFileSet} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Background Image<span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    backgroundImage && backgroundImage.includes("base64") ?
                        <img style={{ width: 200 }} src={backgroundImage && backgroundImage != "" ? backgroundImage.split("@@")[1] : ""} alt="" />
                        :
                        <img style={{ width: 200 }} src={generateFilePath(backgroundImage)} alt="" srcset="" />
                }

                <br />
                <br />
                <br />

                <FileUpload onFileChange={handleFileSet1} />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    )
}
