import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/productCategoryClientImage";

export const addProductCategoryImageValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getProductCategoryImage = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const updateProductCategoryImage = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};

export const deleteProductCategoryImage = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
