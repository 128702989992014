import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/instrumentDetail";

export const addParamountInstrumentsValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getParamountInstruments = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteParamountInstruments = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateParamountInstruments = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
