import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteClientImage, getClientImage } from "../../services/Clients.service";
import { getProductReview, updateProductReview } from "../../services/ProductReview.service";
import { deletewebsiteSettings, getwebsiteSettings } from "../../services/siteData.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { Switch } from "@mui/material";

export default function ProductReview() {
    const [productReviewArr, setProductReviewArr] = useState([]);

    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");

    const handleGetProductReview = async () => {
        try {
            let { data: res } = await getProductReview();
            if (res.success) {
                console.log(res.data)
                setProductReviewArr(res.data);
                setMainArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleChange = async (value, obj) => {
        try {
            // console.log(isApproved)
            let { data: res } = await updateProductReview(obj._id, { status: value });
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetProductReview()
            }
        } catch (err) {
            toastError(err)
        }
    }

    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.comment && el?.comment.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.productObj?.name && el?.productObj?.name.toLowerCase().includes(`${value}`.toLowerCase()))
        ))
        setProductReviewArr([...tempArr])
        setQuery(value)
    }


    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row?.name,
        },
        {
            name: "Comment",
            cell: (row) => <div>{row?.comment}</div>,
        },
        {
            name: "Product",
            selector: (row) => row?.productObj?.name,
        },
        {
            name: "Rating",
            selector: (row) => `${row?.rating} / 5`,
        },
        {
            name: "ACTIVATE",
            cell: (row) => (
                <Switch
                    defaultChecked={row?.isApproved}
                    checked={row?.isApproved}
                    onChange={(e) => handleChange(e.target.checked, row)}
                />
            ),
            width: "33.33%",
        },

    ];




    useEffect(() => {
        handleGetProductReview()
        return () => setProductReviewArr([])
    }, [])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Product Review</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productReviewArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
