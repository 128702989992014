import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/country";

export const addCountryValue = async (formData) => {

    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getCountry = async () => {

    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteCountry = async (id) => {

    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateCountry = async (id, formData) => {

    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
