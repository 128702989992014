import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteParamountInstruments, getParamountInstruments } from "../../services/paramountInstruments.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddParamountInstruments from "./AddParamountInstruments";
import { generateFilePath } from "../Utility/utils";
export default function ParamountInstruments() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [ourServicesArr, setParamountInstrumentsArr] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selected, setSelected] = useState({});


    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");



    const handleGetParamountInstruments = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getParamountInstruments();
            if (res.success) {
                // console.log(res.data)
                setParamountInstrumentsArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteCategory = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deleteParamountInstruments(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetParamountInstruments()
            }
        } catch (err) {
            toastError(err)
        }
    }

    const handleSetSelected = () => {
        setSelected({})
        setIsEditActive(false)
        handleGetParamountInstruments()
    }

    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
            width: "3%"
        },
        {
            name: "Name",
            selector: (row) => row.name,
            width: "10%"
        },
        {
            name: "Description",
            selector: (row) => row.description,
            width: "15%"
        },
        {
            name: "Image",
            cell: (row) => <img src={generateFilePath(row.image)} style={{ width: "100px", height: "100px" }} alt="" />,
            width: "10%"
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setIsEditActive(true)
                            setSelected(row)
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];
    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.description && el?.description.toLowerCase().includes(`${value}`.toLowerCase()))
        ))
        setParamountInstrumentsArr([...tempArr])
        setQuery(value)
    }





    useEffect(() => {
        handleGetParamountInstruments()
        return () => setParamountInstrumentsArr([])
    }, [dataIsAdded])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Add New Instruments</h5>
                            </div>
                            <DashboardBox>
                                <AddParamountInstruments handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Instruments Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={ourServicesArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
