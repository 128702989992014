import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue } from "../../services/subCategories.service";
import { addOurServicesValue } from "../../services/ourServices.service";
import { getProductCategories } from "../../services/productCategories.service";
import FileUpload from "../Utility/FileUpload";
import { addClientImageValue } from "../../services/Clients.service";
import { addClientTestimonialsValue, updateClientTestimonials } from "../../services/clientTestimonials.service";
export default function AddClientTestimonials({ handleSetSelected, data, setDataIsAdded }) {
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [description, setDescription] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [mobilecompanyLogo, setmobilecompanyLogo] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                companyName,
            };
            if (companyLogo != "") {
                obj.companyLogo = companyLogo
            }
            if (mobilecompanyLogo != "") {
                obj.mobilecompanyLogo = mobilecompanyLogo
            }
            let res
            if (isBeingEdited) {
                res = await updateClientTestimonials(data._id, obj)
            }
            else {
                res = await addClientTestimonialsValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };




    const handleFileSet2 = (value) => {
        setCompanyLogo(value);
        console.log(value)
    };


    useEffect(() => {
        if (data && data.name) {
            setName(data.name)
            setDescription(data.description)
            setCompanyName(data.companyName)
            setIsBeingEdited(true);
        }
    }, [data])


    const handleClearState = () => {
        setName("");
        setDescription("");
        setCompanyName("");
        setCompanyLogo("");
        setmobilecompanyLogo("");
    }

    return (
        <form className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Company Name <span className="red">*</span>
                </label>
                <input value={companyName} onChange={(event) => setCompanyName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Company Logo <span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet2} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Company Mobile Logo <span className="red">*</span>
                </label>
                <FileUpload onFileChange={(val)=>setmobilecompanyLogo(val)} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
