import React, { useEffect, useState } from "react";
import Select from "react-select";
import { addOurServicesValue } from "../../services/ourServices.service";
import { addParamountInstrumentsValue } from "../../services/paramountInstruments.service";
import { getProductCategories } from "../../services/productCategories.service";
import { addStandardCategoryValue, updateStandardCategoryById } from "../../services/StandardCategory.service";
import CustomButton from "../Utility/Button";
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
export default function AddStandardCategory({ handleSetSelected, data, setDataIsAdded }) {



    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [url, setUrl] = useState("");
    const [description, setDescription] = useState("");
    const [backgroundImage, setBackgroundImage] = useState("");



    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                logo: image,
                url,
                description,
                backgroundImage,
            };

            let res
            if (isBeingEdited) {
                if (image == "") {
                    delete obj.logo
                }
                res = await updateStandardCategoryById(data._id, obj)
            }
            else {
                res = await addStandardCategoryValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message);
                setDataIsAdded(true);
                handleClearState();
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };




    useEffect(() => {
        if (data && data.name) {
            // console.log("asdsa")
            setName(data.name ? data.name : "");
            setUrl(data.url ? data.url : "")
            setDescription(data?.description ? data?.description : "")
            setBackgroundImage(data?.backgroundImage ? data?.backgroundImage : "")
            setIsBeingEdited(true);
        }
    }, [data])



    const handleClearState = () => {
        setName("");
        setUrl("")
        setDescription("")
        setBackgroundImage("")
        setIsBeingEdited(false);
        handleSetSelected();
    }







    const handleFileSet = (value) => {
        // console.log(value);
        setImage(value);
    };


    const handleFileSetBackgroundImage = (value) => {
        // console.log(value);
        setBackgroundImage(value);
    };



    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image <span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    URL <span className="red">*</span>
                </label>
                <input value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="form-control" />
            </div>



            <div className="col-12">
                <label className="blue-1 fs-12">
                    DESCRIPTION <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>


            <div className="col-12">
                <label className="blue-1 fs-12">
                    BACKGROUND IMAGE <span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSetBackgroundImage} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>




            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
