import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/seo";

export const addSeoValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};
export const getSeo = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const deleteSeo = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updateSeo = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};