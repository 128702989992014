import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import { addResourceValue, getResourceById, updateResource } from "../../services/resources.service";
import { toastError } from "../Utility/ToastUtils";
import { toastSuccess } from "../../utils/toastUtils";
import { useParams } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
import moment from "moment";

function EditBlog() {
    const [heading, setHeading] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [resourceType, setResourceType] = useState("");
    const [resourceObj, setResourceObj] = useState({});
    const [slug, setSlug] = useState("");
    const [mobileimage, setmobileImage] = useState("");
    const [altimage, setaltImage] = useState("");
    let params = useParams()
    const [selectedResource, setSelectedResource] = useState({});
    const [btnText, setBtnText] = useState("");
    const [btnUrl, setBtnUrl] = useState("");
    const [displayOnHome, setDisplayOnHome] = useState(false);
    const [publishedDate, setPublishedDate] = useState(new Date());
    const [showcaseImage, setShowcaseImage] = useState("");
    const [hasForm, setHasForm] = useState(false);

    const options = [
        { value: "BLOGS", label: "Blog" },
        { value: "EVENTS", label: "Events & Exibition" },
        { value: "NEWS", label: "News & Insights" },
        { value: "WEBINARS", label: "Webinars" },
    ];



    const [metaTitle, setMetaTitle] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [metaDescription, setMetaDescription] = useState("");

    const handleFileSet = (value) => {
        // console.log(value);
        setImage(value);
    };



    const handleAttributeValueChange = (e) => {
        console.log("event", e?.value)
        setResourceType(e.value);
        setSelectedResource(e)
        // setCountryId(e.target.value);
    };




    const CreateResources = async (e) => {
        e.preventDefault()
        try {
            let obj = {
                heading,
                description,
                resourceType,
                btnText,
                slug,
                btnUrl,
                displayOnHome,
                metaTitle,
                metaImage,
                metaDescription,
                altimage,
                hasForm,
                showcaseImage,
                publishedDate,
                mobileimage
            }
            if (image != "") {
                obj.image = image
            }

            let { data: res } = await updateResource(resourceObj._id, obj)
            if (res.success) {
                toastSuccess(res.message)
            }

        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }


    const getBlogById = async () => {
        try {
            let { data: res } = await getResourceById(params.id)
            if (res.success) {
                setResourceObj(res?.data);
                setHeading(res?.data?.heading);
                setDescription(res?.data?.description ? res?.data?.description : "");
                setResourceType(res?.data?.resourceType);
                let temp = options.find(el => el?.value == res?.data?.resourceType);
                setSelectedResource(temp);
                setBtnText(res?.data?.btnText);
                setBtnUrl(res?.data?.btnUrl);
                setSlug(res?.data?.slug);
                setDisplayOnHome(res?.data?.displayOnHome);
                setMetaTitle(res?.data?.metaTitle);
                setShowcaseImage(res?.data?.showcaseImage);
                setMetaImage(res?.data?.metaImage);
                setImage(res?.data?.image);
                setmobileImage(res?.data?.mobileimage);
                setaltImage(res?.data?.altimage);
                setMetaDescription(res?.data?.metaDescription);
                setPublishedDate(res?.data?.publishedDate);
                setHasForm(res?.data?.hasForm);

            }
            console.log(res, "single blog")
        }
        catch (err) {
            console.error(err)
            toastError(err)
        }
    }

    useEffect(() => {
        getBlogById()
    }, [])

    const handleFileSet6 = (value) => {
        setMetaImage(value)
    }



    return (
        <main>
            <section className="product-category" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <form className="form">
                        <h5 className="blue-1 mb-4">Add New Resource</h5>
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <DashboardBox>
                                    <div className="row">
                                        <h5 className="blue-1 mb-4">Resource Info</h5>
                                        <div className="col-12 mb-3">
                                            <label>
                                                TITLE <span className="red">*</span>
                                            </label>
                                            <input type="text" value={heading} onChange={(e) => setHeading(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                SLUG <span className="red">*</span>
                                            </label>
                                            <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                BUTTON TEXT <span className="red">*</span>
                                            </label>
                                            <input type="text" value={btnText} onChange={(e) => setBtnText(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                BUTTON URL <span className="red">*</span>
                                            </label>
                                            <input type="text" value={btnUrl} onChange={(e) => setBtnUrl(e.target.value)} className="form-control" />
                                        </div>
                                        {/* <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" />
                    </div> */}
                                        <div className="col-12">
                                            <label>
                                                DESCRIPTION<span className="red">*</span>
                                            </label>
                                            <ReactQuill value={description} onChange={setDescription} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label style={{ textTransform: "uppercase" }}>
                                                Meta Title <span className="red">*</span>
                                            </label>
                                            <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label style={{ textTransform: "uppercase" }}>
                                                Meta Description <span className="red">*</span>
                                            </label>
                                            <input type="text" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label style={{ textTransform: "uppercase" }}>
                                                Meta Image <span className="red">*</span>
                                            </label>
                                            <br />
                                            <br />
                                            <div>

                                                {metaImage && `${metaImage}`.includes("base64") ?
                                                    <img src={metaImage && metaImage != "" ? metaImage.split("@@")[1] : ""} alt="" style={{ height: 100, width: 100 }} srcset="" />
                                                    :
                                                    <img src={generateFilePath(metaImage)} style={{ height: 100, width: 100 }} alt="" />
                                                }

                                            </div>
                                            <br />
                                            <FileUpload onFileChange={handleFileSet6} />
                                        </div>
                                    </div>
                                </DashboardBox>
                            </div>
                            <div className="col-12 col-md-4">
                                <DashboardBox>
                                    <div className="row">
                                        <h5 className="blue-1 mb-4">Basic Info</h5>
                                        {/* <div className="col-12 mb-3">
                      <label>
                        CATEGORY<span className="red">*</span>
                      </label>
                      <Select options={options} />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Tags (Comma Separated)<span className="red">*</span>
                      </label>
                      <Select options={options} isMulti />

                      <div className="form-text fs-12 blue-1">
                        Suggested Tags
                      </div>
                    </div> */}
                                        <div className="col-12 mb-3">
                                            <label>
                                                Image<span className="red">*</span>
                                            </label>
                                            <div>
                                                {
                                                    image && image.includes("base64") ?
                                                        <img src={image && image != "" ? image.split("@@")[1] : ""} style={{ height: 100, width: 100 }} />
                                                        :
                                                        <img src={generateFilePath(image)} style={{ height: 100, width: 100 }} />
                                                }
                                            </div>
                                            <FileUpload onFileChange={handleFileSet} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                Mobile Image<span className="red">*</span>
                                            </label>
                                            <div>
                                                {
                                                    mobileimage && mobileimage.includes("base64") ?
                                                        <img src={mobileimage && mobileimage != "" ? mobileimage.split("@@")[1] : ""} style={{ height: 100, width: 100 }} />
                                                        :
                                                        <img src={generateFilePath(mobileimage)} style={{ height: 100, width: 100 }} />
                                                }
                                            </div>
                                            <FileUpload onFileChange={(val) => setmobileImage(val)} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                Showcase Image<span className="red">*</span>
                                            </label>
                                            <div>
                                                {
                                                    showcaseImage && showcaseImage.includes("base64") ?
                                                        <img src={showcaseImage && showcaseImage != "" ? showcaseImage.split("@@")[1] : ""} style={{ height: 100, width: 100 }} />
                                                        :
                                                        <img src={generateFilePath(showcaseImage)} style={{ height: 100, width: 100 }} />
                                                }
                                            </div>
                                            <FileUpload onFileChange={(val) => setShowcaseImage(val)} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                Image Alt Tag<span className="red">*</span>
                                            </label>
                                            <input type="text" value={altimage} onChange={(e) => setaltImage(e.target.value)} className="form-control" />

                                        </div>
                                        <div className="col-12 mb-3">
                                            <label>
                                                Publish Date<span className="red">*</span>
                                            </label>
                                            <input type="date" value={moment(publishedDate).format("YYYY-MM-DD")} onChange={(e) => setPublishedDate(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-12">
                                            <label className="blue-1 fs-12">Resource Type
                                                <span className="red">*</span>
                                            </label>
                                            <Select defaultInputValue={resourceType} value={selectedResource} onChange={handleAttributeValueChange} options={options && options.length > 0 ? options : []} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="category-status"
                                                    checked={hasForm}
                                                    value="option1"
                                                    onChange={() => setHasForm(prev => !prev)}
                                                    id="publish-checkbox"
                                                />
                                                <label
                                                    className="form-check-label fs-14"
                                                    htmlFor="publish-checkbox"
                                                >
                                                    Has Form
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="category-status"
                                                    checked={displayOnHome}
                                                    value="option1"
                                                    onChange={() => setDisplayOnHome(prev => !prev)}
                                                    id="publish-checkbox"
                                                />
                                                <label
                                                    className="form-check-label fs-14"
                                                    htmlFor="publish-checkbox"
                                                >
                                                    SHOW ON HOMEPAGE
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <CustomButton
                                                isBtn
                                                iconName="fa-solid fa-check"
                                                type={"button"}
                                                btntype={"button"}
                                                btnName="Update"
                                                ClickEvent={CreateResources}
                                            />
                                        </div>
                                    </div>
                                </DashboardBox>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
}

export default EditBlog;
