import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getmenuCategories, deletemenuCategories } from "../../services/menuCategories.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal, EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddMenuCategory from "./AddMenuCategory";
export default function MenuCategories() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selectedCountry, setSelectedMenuCategories] = useState({});
    const [menuCategoriesArr, setmenuCategoriesArr] = useState([]);

    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");
    const handleGetAllMenuCategories = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getmenuCategories();
            if (res.success) {
                console.log(res.data)
                setmenuCategoriesArr(res.data);
                setMainArr(res.data);

            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteCategory = async (id) => {
        try {
            console.log("asd a")
            let { data: res } = await deletemenuCategories(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetAllMenuCategories()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Menu Category Name",
            selector: (row) => row.name,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/menuCategories"
                        deletePath="/generalData/menuCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setIsEditActive(true)
                            // setModalType("editCountry");
                            // setModalName("Edit Country");
                            setSelectedMenuCategories(row)
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];

    const handleSetSelected = () => {
        setSelectedMenuCategories({})
        handleGetAllMenuCategories()
        setIsEditActive(false)
    }



    useEffect(() => {
        handleGetAllMenuCategories()
        return () => setmenuCategoriesArr([])
    }, [])



    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            el.name.toLowerCase().includes(`${value}`.toLowerCase())
        ))
        setmenuCategoriesArr([...tempArr])
        setQuery(value)
    }






    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} Menu Categories</h5>
                            </div>
                            <DashboardBox>
                                <AddMenuCategory handleSetSelected={handleSetSelected} data={isEditActive ? selectedCountry : {}} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Menu Categories Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={menuCategoriesArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
