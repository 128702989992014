import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import { toastError } from "../../utils/toastUtils";
import { DeleteProductById, getProducts } from "../../services/product.service";
import { url } from "../../services/url.service";
import SearchBox from "../Utility/SearchBox";
import { generateFilePath } from "../Utility/utils";

function ProductList() {
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [productsArr, setProductsArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");


  const products_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "15%",
    },
    {
      name: "OverView",
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `${row.overView.slice(0, 80)}...` }}></div>,
      width: "15%",
    },
    {
      name: "Description",
      cell: (row) => <div dangerouslySetInnerHTML={{ __html: `${row.description.slice(0, 80)}...` }}></div>,
      width: "15%",
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img height="84px" width="56px" alt={row.Name} src={generateFilePath(row.image)} />
      ),
      width: "15%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row._id}
            remove
            edit
            isRedirected={true}
            editPath={`/Product/EditProduct/${row._id}`}
            deletePath="/Product/Product-List"
            onDeleteClick={(e) => {
              e.preventDefault()
              handleDeleteProduct(row._id)
              // handleDeleteStandardCategory(row._id)
            }}

          // detailClick={(e) => {
          //   e.preventDefault();
          //   setModalBox(true);
          //   setModalType("show-product");
          //   setModalName(row.Name);
          // }}
          />

          <AddModal
            ModalBox={ModalBox}
            setModalBox={setModalBox}
            name={ModalName}
            ModalType={ModalType}
          />
        </>
      ),
    },
  ];



  const handleGetProduct = async () => {
    try {
      let { data: res } = await getProducts()
      console.log(res, "resreserwresers")
      if (res.success) {
        setProductsArr(res.data)
        setMainArr(res.data)
      }
      console.log(res.data)
    }
    catch (err) {
      toastError(err)
      console.error(err)
    }
  }


  const handleDeleteProduct = async (id) => {
    try {
      let { data: res } = await DeleteProductById(id)
      if (res.success) {
        handleGetProduct()
      }
      console.log(res.data)
    }
    catch (err) {
      toastError(err)
      console.error(err)
    }
  }



  const filterData = (value) => {
    let tempArr = mainArr;
    tempArr = tempArr.filter(el => (
      (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
      || (el?.overView && el?.overView.toLowerCase().includes(`${value}`.toLowerCase()))
      || (el?.description && el?.description.toLowerCase().includes(`${value}`.toLowerCase()))
    ))
    setProductsArr([...tempArr])
    setQuery(value)
  }


  useEffect(() => {
    handleGetProduct()
  }, [])





  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Product List</h5>
                <div className="d-flex">

                  <div className="me-5">
                    <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                  </div>
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW PRODUCT"
                    path="/Product/AddProduct"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={products_columns}
                  data={productsArr}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProductList;
