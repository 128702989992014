import React, { useEffect, useState } from "react";
import { addProductCategoriesValue, updateproductCategory } from "../../services/productCategories.service";
import { addSiteMapData, updateSiteMapData } from "../../services/SitemapData.service";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
export default function AddSiteMapData({ handleSetSelected, data, setDataIsAdded }) {
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [url, setUrl] = useState("");
    const [changefreq, setChangefreq] = useState("");
    const [priority, setPriority] = useState("");

    const handleSubmit = async () => {
        try {
            let obj = {
                url,
                changefreq,
                priority,
            };
            let res
            if (isBeingEdited) {
                res = await updateSiteMapData(data._id, obj)
            }
            else {
                res = await addSiteMapData(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true);
                handleClearState()
            }
        } catch (err) {
            toastError(err)
        }
    };



    useEffect(() => {
        console.log(data, "dataa")
        if (data && data.url) {
            setUrl(data?.url ? data?.url : "");
            setChangefreq(data?.changefreq ? data?.changefreq : "");
            setPriority(data?.priority ? data?.priority : "");
            setIsBeingEdited(true)
        }
    }, [data])

    const handleClearState = () => {
        setUrl("");
        setChangefreq("");
        setPriority("");
        setIsBeingEdited(false);
        handleSetSelected();
    }

    return (
        <form className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    URL <span className="red">*</span>
                </label>
                <input value={url} onChange={(event) => setUrl(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    CHANGE FREQUENCY <span className="red">*</span>
                </label>
                <input value={changefreq} onChange={(event) => setChangefreq(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    PRIORITY <span className="red">*</span>
                </label>
                <input value={priority} onChange={(event) => setPriority(event.target.value)} type="number" className="form-control" />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}