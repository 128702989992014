import React, { useEffect, useState } from 'react'
import { addSeoValue, updateSeo } from '../../services/seo.service';
import CustomButton from '../Utility/Button';
import FileUpload from '../Utility/FileUpload';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';

export default function AddSeo({ setDataIsAdded, currentSelectedSeo, setCurrentSelectedSeo }) {

    const [pageUrl, setPageUrl] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [metaKeywords, setMetaKeywords] = useState("");


    const [canonical, setCanonical] = useState("");
    const [robots, setRobots] = useState("");
    const [ogLocale, setOgLocale] = useState("");
    const [ogType, setOgType] = useState("");
    const [ogTitle, setOgTitle] = useState("");
    const [ogDescription, setOgDescription] = useState("");
    const [ogurl, setOgurl] = useState("");
    const [ogSiteUrl, setOgSiteUrl] = useState("");
    const [twitterCard, setTwitterCard] = useState("");
    const [twitterTitle, setTwitterTitle] = useState("");
    const [twitterDescription, setTwitterDescription] = useState("");
    const [twitterSite, setTwitterSite] = useState("");









    const handleFileSet = (value) => {
        // console.log(value);
        setMetaImage(value);
    };

    const handleFileSet2 = (value) => {
        // console.log(value);
        setTwitterCard(value);
    };



    const handleSubmit = async () => {
        try {
            let obj = {
                pageUrl,
                metaTitle,
                metaKeywords,
                metaDescription,
                metaImage,
                canonical,
                robots,
                ogLocale,
                ogType,
                ogTitle,
                ogDescription,
                ogurl,
                ogSiteUrl,
                twitterCard,
                twitterTitle,
                twitterDescription,
                twitterSite,
            };
            let res
            if (currentSelectedSeo?.pageUrl) {
                res = await updateSeo(currentSelectedSeo?._id, obj)
            }
            else {
                res = await addSeoValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };



    useEffect(() => {
        if (currentSelectedSeo.pageUrl) {
            console.log(currentSelectedSeo, "currentSelectedSeo")
            setPageUrl(currentSelectedSeo?.pageUrl)
            setMetaTitle(currentSelectedSeo?.metaTitle)
            setMetaDescription(currentSelectedSeo?.metaDescription)
            setMetaImage(currentSelectedSeo?.metaImage)
            setMetaKeywords(currentSelectedSeo?.metaKeywords)
            setCanonical(currentSelectedSeo?.canonical)
            setRobots(currentSelectedSeo?.robots)
            setOgLocale(currentSelectedSeo?.ogLocale)
            setOgType(currentSelectedSeo?.ogType)
            setOgTitle(currentSelectedSeo?.ogTitle)
            setOgDescription(currentSelectedSeo?.ogDescription)
            setOgurl(currentSelectedSeo?.ogurl)
            setOgSiteUrl(currentSelectedSeo?.ogSiteUrl)
            setTwitterCard(currentSelectedSeo?.twitterCard)
            setTwitterTitle(currentSelectedSeo?.twitterTitle)
            setTwitterDescription(currentSelectedSeo?.twitterDescription)
            setTwitterSite(currentSelectedSeo?.twitterSite)
        }

    }, [currentSelectedSeo])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Page Url<span className="red">*</span>
                </label>
                <input onChange={(event) => setPageUrl(event.target.value)} value={pageUrl} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Meta Title<span className="red">*</span>
                </label>
                <input onChange={(event) => setMetaTitle(event.target.value)} value={metaTitle} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Meta Description<span className="red">*</span>
                </label>
                <input onChange={(event) => setMetaDescription(event.target.value)} value={metaDescription} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Meta Keywords<span className="red">*</span>
                </label>
                <input onChange={(event) => setMetaKeywords(event.target.value)} value={metaKeywords} className="form-control" />
            </div>




            <div className="col-12">
                <label className="blue-1 fs-12">
                    Canonical<span className="red">*</span>
                </label>
                <input onChange={(event) => setCanonical(event.target.value)} value={canonical} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Robots<span className="red">*</span>
                </label>
                <input onChange={(event) => setRobots(event.target.value)} value={robots} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Locale<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgLocale(event.target.value)} value={ogLocale} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Type<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgType(event.target.value)} value={ogType} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Title<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgTitle(event.target.value)} value={ogTitle} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Description<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgDescription(event.target.value)} value={ogDescription} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Url<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgurl(event.target.value)} value={ogurl} className="form-control" />
            </div>
            {/* <div className="col-12">
                <label className="blue-1 fs-12">
                    OG Site Url<span className="red">*</span>
                </label>
                <input onChange={(event) => setOgSiteUrl(event.target.value)} value={ogSiteUrl} className="form-control" />
            </div> */}

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Twitter Title<span className="red">*</span>
                </label>
                <input onChange={(event) => setTwitterTitle(event.target.value)} value={twitterTitle} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Twitter Description<span className="red">*</span>
                </label>
                <input onChange={(event) => setTwitterDescription(event.target.value)} value={twitterDescription} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Twitter Site<span className="red">*</span>
                </label>
                <input onChange={(event) => setTwitterSite(event.target.value)} value={twitterSite} className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Twitter Card<span className="red">*</span>
                </label>
                <input onChange={(event) => setTwitterCard(event.target.value)} value={twitterCard} className="form-control" />
            </div>




            <div className="col-12">
                <label className="blue-1 fs-12">
                    Meta Image<span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    metaImage && metaImage.includes("base64") ?
                        <img style={{ width: 200 }} src={metaImage && metaImage != "" ? metaImage.split("@@")[1] : ""} alt="" />
                        :
                        <img style={{ width: 200 }} src={generateFilePath(metaImage)} alt="" srcset="" />
                }

                <br />
                <br />
                <br />

                <FileUpload onFileChange={handleFileSet} />
            </div>

            {/* <div className="col-12">
                <label className="blue-1 fs-12">
                    Twitter card<span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    twitterCard && twitterCard.includes("base64") ?
                        <img style={{ width: 200 }} src={twitterCard} alt="" />
                        :
                        <img style={{ width: 200 }} src={generateFilePath(twitterCard)} alt="" srcset="" />
                }

                <br />
                <br />
                <br />

                <FileUpload onFileChange={handleFileSet2} />
            </div> */}

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    )
}
