import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteClientImage, getClientImage } from "../../services/Clients.service";
import { deletewebsiteSettings, getwebsiteSettings } from "../../services/siteData.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddSiteDetails from "./AddSiteDetails";
export default function SiteDetails() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [ourServicesArr, setWebsiteSettingsArr] = useState([]);


    const handleGetClientImages = async () => {
        try {
            let { data: res } = await getwebsiteSettings();
            if (res.success) {
                console.log(res.data)
                setWebsiteSettingsArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteCategory = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deletewebsiteSettings(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetClientImages()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Installation Worldwide",
            selector: (row) => row.installationWorldwide,
        },
        {
            name: "Lab Installed",
            selector: (row) => row.labInstalled,
        },
        {
            name: "Year Of Experience",
            selector: (row) => row.yearOfExperience,
        },
        {
            name: "Country Export",
            selector: (row) => row.countryExport,
        },
        {
            name: "Phone Number 1",
            selector: (row) => row.phone1,
        },
        {
            name: "Phone Number 2",
            selector: (row) => row.phone2,
        },
        {
            name: "Address",
            selector: (row) => row.address,
        },
        {
            name: "Facebook Link",
            selector: (row) => row.facebookLink,
        },
        {
            name: "LinkedIn Link",
            selector: (row) => row.linkedInLink,
        },
        {
            name: "Twitter Link",
            selector: (row) => row.twitterLink,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        // edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setModalBox(true)
                            setModalType("editSubCategories");
                            setModalName("Edit SubCategories");
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];




    useEffect(() => {
        handleGetClientImages()
        return () => setWebsiteSettingsArr([])
    }, [dataIsAdded])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Add Site Details</h5>
                            </div>
                            <DashboardBox>
                                <AddSiteDetails setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Site Details</h5>
                                {/* <SearchBox extraClass="bg-white" /> */}
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={ourServicesArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
