import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/standardCategory";

export const addStandardCategoryValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getStandardCategory = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteStandardCategoryById = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateStandardCategoryById = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};

