import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResourceById } from "../../services/resources.service";
import { url } from "../../services/url.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardBox } from "../Utility/DashboardBox";
import { generateFilePath } from "../Utility/utils";

function ShowBlog() {
  const params = useParams()
  const [resourceData, setResourceData] = useState({});


  const handleGetResourceById = async () => {
    try {

      let { data: res } = await getResourceById(params.id)
      if (res.success) {
        console.log(res.data)
        setResourceData(res.data)
      }
    } catch (error) {
      console.error(error)
      toastError(error)
    }
  }



  useEffect(() => {
    handleGetResourceById()
    return () => setResourceData({})
  }, [])



  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <DashboardBox className="blue-1">
            <h5 className="blue-1">{resourceData.heading}</h5>
            <p className="fs-12">From Super Admin  {new Date(resourceData.createdAt).toDateString()}
              {" "}
              {new Date(resourceData.createdAt).getHours()}:
              {new Date(resourceData.createdAt).getMinutes()}</p>
            <img src={generateFilePath(resourceData.image)} alt="" style={{ height: 350 }} className="my-4" />
            <p className="fs-14">
              <div dangerouslySetInnerHTML={{ __html: resourceData.description }}></div>
            </p>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default ShowBlog;
