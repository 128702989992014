import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/getInTouch";


export const getInTouch = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

