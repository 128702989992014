import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/websiteSetting";

export const addwebsiteSettingsValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};
export const getwebsiteSettings = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const deletewebsiteSettings = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updatewebsiteSettings = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};