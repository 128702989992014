import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/city";

export const addCityValue = async (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getCity = async () => {
  return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteCity = async (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateCity = async (id, formData) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
