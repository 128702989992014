import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue } from "../../services/subCategories.service";
import { addOurServicesValue } from "../../services/ourServices.service";
import { getProductCategories } from "../../services/productCategories.service";
import FileUpload from "../Utility/FileUpload";
import { addClientImageValue } from "../../services/Clients.service";
export default function AddClients({ setDataIsAdded }) {


    const [logo1, setLogo1] = useState("");
    const [logo2, setLogo2] = useState("");
    const [mobileImage1, setmobileImage1] = useState("");
    const [mobileImage2, setmobileImage2] = useState("");
    const [altimage1, setaltimage1] = useState("");
    const [altimage2, setaltimage2] = useState("");


    const handleSubmit = async () => {
        try {
            let obj = {
                image1: logo1,
                image2: logo2,
                mobileImage1,
                mobileImage2
                ,altimage1, altimage2
            };

            let { data: res } = await addClientImageValue(obj)
            if (res.success) {
                toastSuccess(res.message)
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };



    const handleFileSet = (value) => {
        // console.log(value);
        setLogo1(value);
    };
    const handleFileSet2 = (value) => {
        // console.log(value);
        setLogo2(value);
    };

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image 1<span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                  Alt  Image 1
                </label>
                <input onChange={(event) => setaltimage1(event.target.value)} type="text" accept="image/*" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                   Mobile Image 1<span className="red">*</span>
                </label>
                <FileUpload onFileChange={(val)=>setmobileImage1(val)} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
          
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image 2<span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet2} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                  Alt  Image 2
                </label>
                <input onChange={(event) => setaltimage2(event.target.value)} type="text" accept="image/*" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                Mobile Image 2<span className="red">*</span>
                </label>
                <FileUpload onFileChange={(val)=>setmobileImage2(val)} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
        
            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
