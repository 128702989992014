import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { addCountryValue, updateCountry } from "../../services/Country.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import EditCountry from "./EditCountry";
export default function AddCountry({ handleSetSelectedCountry, data }) {
    console.log(handleSetSelectedCountry, data, "asd")
    const [countryId, setCountryId] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const [name, setName] = useState("");


    const handleSubmit = async () => {
        try {
            let obj = {
                name,
            };
            let res = {}

            if (isBeingEdited) {
                res = await updateCountry(data._id, obj)
            }
            else {
                res = await addCountryValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
            }
            console.log(res.data)
        } catch (err) {
            toastError(err)
        }



    };

    const handleClearState = () => {
        setName("")
        setIsBeingEdited(false)
        handleSetSelectedCountry()
    }


    useEffect(() => {
        if (data && data.name) {
            setName(data.name)
            setIsBeingEdited(true)
        }
    }, [data])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>


            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
