import React, { useEffect, useState } from "react";
// import Select from "react-select";
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import { addProduct, getProductById, handleEditProduct } from "../../../services/product.service";
import { getProductCategories } from "../../../services/productCategories.service";
import { getStandardCategory } from "../../../services/StandardCategory.service";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useParams } from "react-router-dom";
import { generateFilePath } from "../../Utility/utils";
import { url } from "../../../services/url.service";
export const Feature = ({ index, featureName, setFeatureName, featureimage, setFeatureImage, featureDescription, setFeatureDescription, setAltFeatureImage, setMobileFeatureImage, mobilefeatureimage, altfeatureimage }) => {

  const handleFileSet2 = (value) => {
    setFeatureImage(value, index - 1)
  }

  const setQuillResult = (value) => {
    setFeatureDescription(value, index - 1)
  }


  return (
    <>
      <h5 className="blue-1 mb-3">Feature {index}</h5>

      <div className="col-12 col-md-6 mb-3">
        <label>
          Name <span className="red">*</span>
        </label>
        <input onChange={(e) => setFeatureName(e.target.value, index - 1)} value={featureName} type="text" className="form-control" />
      </div>
      <div className="border-bottom pb-3 mb-4 row">
        <div className="col-6 mb-3">
          <label>
            Image<span className="red">*</span>
          </label>
          <div>

            {featureimage && `${featureimage}`.includes("base64") ?
              <img src={featureimage && featureimage != "" ? featureimage.split("@@")[1] : ""} alt="" style={{ height: 100, width: 100 }} srcset="" />
              :
              <img src={generateFilePath(featureimage)} style={{ height: 100, width: 100 }} alt="" />
            }
          </div>
          <FileUpload onFileChange={handleFileSet2} />
        </div>
        {/* <div className="col-6 mb-3">
          <label>
            Mobile Image<span className="red">*</span>
          </label>
          <div>

            {mobilefeatureimage && `${mobilefeatureimage}`.includes("base64") ?
              <img src={mobilefeatureimage} alt="" style={{ height: 100, width: 100 }} srcset="" />
              :
              <img src={generateFilePath(mobilefeatureimage)} style={{ height: 100, width: 100 }} alt="" />
            }
          </div>
          <FileUpload onFileChange={(val) => setMobileFeatureImage(val, index - 1)} />
        </div> */}
        <div className="col-6 col-md-6 mb-3">
          <label>
            Alt Image Tag <span className="red">*</span>
          </label>
          <input onChange={(e) => setAltFeatureImage(e.target.value, index - 1)} value={altfeatureimage} type="text" className="form-control" />
        </div>
      </div>
      <div className="border-bottom pb-3 mb-4 row">
        <h5 className="blue-1 mb-4">Description</h5>
        <div className="col-12 mb-3">
          <ReactQuill
            theme="snow"
            value={featureDescription}
            onChange={setQuillResult}
          />
        </div>
      </div>

    </>

  )

}



export const EndUse = ({ index, setEndUseName, endUseImage, setEndUseImage, endUseName, endUseUrl, setEndUseUrl, mobileendUseImage, altendUseImage, setEndUseAltImage, setEndUseMobileImage }) => {
  const handleFileSet2 = (value) => {
    setEndUseImage(value, index - 1)
  }
  return (
    <>
      <h5 className="blue-1 mb-3">End Use {index}</h5>
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <label>
            Name <span className="red">*</span>
          </label>
          <input onChange={(e) => setEndUseName(e.target.value, index - 1)} value={endUseName} type="text" className="form-control" />
        </div>
        <div className="col-12 col-md-6 mb-3">
          <label>
            Url <span className="red">*</span>
          </label>
          <input onChange={(e) => setEndUseUrl(e.target.value, index - 1)} value={endUseUrl} type="text" className="form-control" />
        </div>
        <div className="border-bottom pb-3 mb-4 row">
          <div className="col-6 mb-3">
            <label>
              Image<span className="red">*</span>
            </label>
            <div>

              {endUseImage && `${endUseImage}`.includes("base64") ?
                <img src={endUseImage && endUseImage != "" ? endUseImage.split("@@")[1] : ""} alt="" style={{ height: 100, width: 100 }} srcset="" />
                :
                <img src={generateFilePath(endUseImage)} style={{ height: 100, width: 100 }} alt="" />
              }
            </div>
            <FileUpload onFileChange={handleFileSet2} />
            {/* </div> */}
          </div>

          {/* <div className="col-6 mb-3">
            <label>
              Mobile Image <span className="red">*</span>
            </label>
            <div>

              {mobileendUseImage && `${mobileendUseImage}`.includes("base64") ?
                <img src={mobileendUseImage} alt="" style={{ height: 100, width: 100 }} srcset="" />
                :
                <img src={generateFilePath(mobileendUseImage)} style={{ height: 100, width: 100 }} alt="" />
              }
            </div>
            <FileUpload onFileChange={(val) => setEndUseMobileImage(val, index - 1)} />
          </div> */}
          <div className="col-6 col-md-6 mb-3">
            <label>
              Alt Image Tag <span className="red">*</span>
            </label>
            <input onChange={(e) => setEndUseAltImage(e.target.value, index - 1)} value={altendUseImage} type="text" className="form-control" />
          </div>
        </div>
      </div>
    </>

  )

}


export const TechnicalSpecifications = ({ setTechnicalSpecificationCategoryName, setSpecificationArr, categoryName, specificationArr, index, }) => {
  const handleSpecificationAdd = () => {
    setSpecificationArr([...specificationArr,
    {
      name: "",
      value: ""
    }
    ], index - 1)
  }
  const handleSpecificationRemove = () => {
    if (specificationArr.length > 1) {
      let tempArr = specificationArr.filter((el, index) => index != specificationArr.length - 1)
      setSpecificationArr([...tempArr], index - 1)
    }
  }
  const handleAddSpecificationName = (value, indexX) => {
    let tempArr = specificationArr.map((el, indexValue) => {
      if (indexValue == indexX) {
        el.name = value
      }
      return el
    })
    setSpecificationArr([...tempArr], index - 1)
  }
  const handleAddSpecificationvalue = (value, indexX) => {
    let tempArr = specificationArr.map((el, indexValue) => {
      if (indexValue == indexX) {
        el.value = value
      }
      return el
    })
    setSpecificationArr([...tempArr], index - 1)
  }
  return (
    <>
      <div className="row border-bottom mb-3">
        <h5 className="blue-1 mb-3">Technical Specifications {index}</h5>
        <div className="col-12 col-md-6 mb-3">
          <label>
            Heading <span className="red">*</span>
          </label>
          <input onChange={(e) => setTechnicalSpecificationCategoryName(e.target.value, index - 1)} value={categoryName} type="text" className="form-control" />
        </div>
      </div>
      <div className="border-bottom p-4 mb-4 row">
        <div className="row">
          <h5 className="col-8 blue-1 mb-4">Technical Specifications Info</h5>
          <h5 className="col-4 d-flex justify-content-evenly blue-1 mb-4">
            <CustomButton btntype="button" ClickEvent={handleSpecificationAdd} isBtn btnName="+" />
            <CustomButton btntype="button" ClickEvent={handleSpecificationRemove} isBtn btnName="-" />
          </h5>
        </div>
        <div className="col-12 mb-3">
          {
            specificationArr && specificationArr.length > 0 && specificationArr.map((el, index) => {
              return (
                <div className="row border-bottom " key={index}>
                  <div className="col-12 col-md-6 mb-3">
                    <label>
                      Specification <span className="red">*</span>
                    </label>
                    <input onChange={(e) => handleAddSpecificationName(e.target.value, index)} value={el.name} type="text" className="form-control" />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <label>
                      Value <span className="red">*</span>
                    </label>
                    <input onChange={(e) => handleAddSpecificationvalue(e.target.value, index)} value={el.value} type="text" className="form-control" />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

function EditGeneralProduct() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [image, setImage] = useState("");
  const [mobileimage, setmobileImage] = useState("");
  const [altimage, setaltImage] = useState("");
  const [video, setVideo] = useState("");
  const [videoName, setVideoName] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [description, setdescription] = useState("");
  const [overView, setOverView] = useState("");
  const [name, setName] = useState("");
  const [productCategoriesArr, setProductCategoriesArr] = useState([]);
  const [standardCategoryArr, setStandardCategoryArr] = useState([]);
  const [productCategoryArr, setProductCategoryArr] = useState([]);

  const [metaImage, setMetaImage] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [canonicalLink, setCanonicalLink] = useState("");
  const [genericName, setGenericName] = useState("");

  const [brochureFile, setBrochureFile] = useState("");
  const [slug, setSlug] = useState("");

  const [overViewHeading, setOverViewHeading] = useState("");
  const [overViewImage, setOverViewImage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [altbackgroundImage, setaltBackgroundImage] = useState("");
  const [mobilebackgroundImage, setmobileBackgroundImage] = useState("");
  const params = useParams()
  const [endUseArr, setEndUseArr] = useState([
    {
      image: "",
      name: "",
      altimage: "",
      // mobileimage: "",
    }
  ]);

  const [featureArr, setFeatureArr] = useState([
    {
      featureName: "",
      featureDescription: "",
      featureimage: "",
      // mobilefeatureimage: "",
      altfeatureimage: ""
    },
  ]);

  const [faqArr, setFaqArr] = useState([
    {
      heading: "",
      description: ""
    }
  ]);

  const [technicalSpecificationsArr, setTechnicalSpecificationsArr] = useState([
    {
      categoryName: "",
      specificationArr: [
        {
          name: "",
          value: ""
        }
      ]
    }
  ]);

  const [videoUrlOf, setVideoUrlOf] = useState("");




  const handleGetProductData = async (productCatArr, standardCatArr) => {
    try {

      let { data: res } = await getProductById(params.id);
      if (res?.success) {
        console.log(res.data, "handleGetProductData")
        setFeatureArr(res?.data?.featuresArr)
        setTechnicalSpecificationsArr(res?.data?.technicalSpecificationArr)
        setEndUseArr(res?.data?.endUseArr)

        let tempArr = standardCatArr.map(el => {
          if (res?.data?.standardCategoryIdArr.some(ele => ele.standardCategoryId == el._id)) {
            el.checked = true
            let tempstandardCategoryIdObj = res?.data?.standardCategoryIdArr.find(ele => ele.standardCategoryId == el._id)
            el.standardArr = el.standardArr.map(ele => {
              if (tempstandardCategoryIdObj.standardIdArr.some(elx => elx.standardId == ele._id)) {
                ele.checked = true
              }
              return ele
            })
          }
          return el
        })
        console.log(res?.data)
        setVideoName(res?.data?.productVideo?.name)
        setVideoUrlOf(res?.data?.productVideo?.videoUrlOf)
        setVideoDescription(res?.data?.productVideo?.description)
        setOverView(res?.data?.overView)
        setName(res?.data?.name)
        setSlug(res?.data?.slug ? res?.data?.slug : "")
        setdescription(res?.data?.description);
        setGenericName(res?.data?.genericName);

        setOverViewHeading(res?.data?.overViewHeading)
        setStandardCategoryArr([...tempArr])
        setProductCategoryArr(res?.data?.productCategoryIdArr?.map(el => { return el.productCategoryId }))
        setVideo(res?.data?.productVideo?.videoUrl)
        setVideoThumbnail(res?.data?.productVideo?.videoThumbnail)
        setOverViewImage(res?.data?.overViewImage)
        setImage(res?.data?.image)
        // setmobileImage(res?.data?.mobileimage)
        setaltImage(res?.data?.altimage)
        setMetaImage(res?.data?.metaImage)
        setMetaTitle(res?.data?.metaTitle)
        setMetaDescription(res?.data?.metaDescription)
        setCanonicalLink(res?.data?.canonicalLink)
        setBrochureFile(res?.data?.brochureFile)

        if (res?.data?.faqArr && res?.data?.faqArr.length > 0) {
          setFaqArr(res?.data?.faqArr)
        }
        setBackgroundImage(res?.data?.backgroundImage)
        setaltBackgroundImage(res?.data?.altbackgroundImage)
        setmobileBackgroundImage(res?.data?.mobilebackgroundImage)
      }
    } catch (err) {
      toastError(err)
    }
  }






  ///////faq
  const handleFaqAdd = () => {
    setFaqArr([...faqArr, {
      heading: "",
      description: "",
    }])
  }
  const handleFaqRemove = () => {
    if (faqArr.length > 1) {
      let tempArr = faqArr.filter((el, index) => index != faqArr.length - 1)
      setFaqArr([...tempArr])
    }
  }
  const setFaqValues = (value, indexValue, key) => {
    setFaqArr(previousValue => {
      previousValue[indexValue][key] = value
      return [...previousValue]
    })
  }
  // console.log(value, indexValue)








  ////////setting End Use
  const setEndUseUrl = (value, indexValue) => {
    setEndUseArr(previousValue => {
      previousValue[indexValue].url = value
      return [...previousValue]
    })
  }

  const setEndUseName = (value, indexValue) => {
    setEndUseArr(previousValue => {
      previousValue[indexValue].name = value
      return [...previousValue]
    })
  }
  const setEndUseImage = (value, indexValue) => {
    setEndUseArr(previousValue => {
      previousValue[indexValue].image = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }


  const setEndUseMobileImage = (value, indexValue) => {
    setEndUseArr(previousValue => {
      previousValue[indexValue].mobileimage = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }


  const setEndUseAltImage = (value, indexValue) => {
    setEndUseArr(previousValue => {
      previousValue[indexValue].altimage = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }
  const handleEndUseAdd = () => {
    setEndUseArr([...endUseArr, {
      image: "",
      name: "",
      url: "",
      altimage: "",
      // mobileimage: "",
    }])
  }
  const handleEndUseRemove = () => {
    if (endUseArr.length > 1) {
      let tempArr = endUseArr.filter((el, index) => index != endUseArr.length - 1)
      setEndUseArr([...tempArr])
    }
  }
  ////////setting features
  const setFeatureName = (value, indexValue) => {
    setFeatureArr(previousValue => {
      previousValue[indexValue].featureName = value
      return [...previousValue]
    })
  }
  const setFeatureImage = (value, indexValue) => {
    setFeatureArr(previousValue => {
      previousValue[indexValue].featureimage = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }
  const setFeatureDescription = (value, indexValue) => {
    setFeatureArr(previousValue => {
      previousValue[indexValue].featureDescription = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }
  const handleFeatureAdd = () => {
    setFeatureArr([...featureArr, {
      featureName: "",
      featureDescription: "",
      featureimage: "",
      mobilefeatureimage: "",
      altfeatureimage: ""
    }])
  }

  const setMobileFeatureImage = (value, indexValue) => {
    setFeatureArr(previousValue => {
      previousValue[indexValue].mobilefeatureimage = value
      return [...previousValue]
    })
    console.log(value, indexValue)
  }

  const setAltFeatureImage = (value, indexValue) => {
    setFeatureArr(previousValue => {
      previousValue[indexValue].altfeatureimage = value
      return [...previousValue]
    })
  }



  const handleFeatureRemove = () => {
    if (featureArr.length > 1) {
      let tempArr = featureArr.filter((el, index) => index != featureArr.length - 1)
      setFeatureArr([...tempArr])
    }
  }



  ////////setting TechnicalSpecs
  const setTechnicalSpecificationCategoryName = (value, indexValue) => {
    setTechnicalSpecificationsArr(previousValue => {
      previousValue[indexValue].categoryName = value
      return [...previousValue]
    })
  }


  const setSpecificationArr = (value, indexValue) => {
    setTechnicalSpecificationsArr(previousValue => {
      previousValue[indexValue].specificationArr = [...value]
      return [...previousValue]
    })
  }


  const handleTechnicalSpecificationsAdd = () => {
    setTechnicalSpecificationsArr([...technicalSpecificationsArr, {
      categoryName: "",
      specificationArr: [
        {
          name: "",
          value: ""
        }
      ]
    }])
  }


  const handleTechnicalSpecificationsRemove = () => {
    if (technicalSpecificationsArr.length > 1) {
      let tempArr = technicalSpecificationsArr.filter((el, index) => index != technicalSpecificationsArr.length - 1)
      setTechnicalSpecificationsArr([...tempArr])
    }
  }



  const handleFileSet2 = (value) => {
    setVideo(value)
  }


  const handleFileSet3 = (value) => {
    setVideoThumbnail(value)
  }



  const handleFileSet4 = (value) => {
    setOverViewImage(value)
  }

  const handleFileSet1 = (value) => {
    setImage(value)
  }

  const handleFileSet7 = (value) => {
    setBrochureFile(value)
  }
  const handleFileSet6 = (value) => {
    setMetaImage(value)
  }

  const handleProductSubmit = async () => {
    try {

      let tempStandardCategoryArr = standardCategoryArr.filter(el => el.checked).map(el => {
        let obj = {
          standardCategoryId: el._id,
          standardIdArr: el.standardArr.filter(ele => ele.checked).map(elex => {
            return { standardId: elex._id }
          })
        }
        return obj
      })

      console.log(tempStandardCategoryArr, "tempStandardCategoryArr")
      let obj = {
        name: name,
        productCategoryIdArr: productCategoryArr.map(el => {
          return { productCategoryId: el }
        }),
        standardCategoryIdArr: tempStandardCategoryArr,
        description,
        overView,
        overViewHeading,
        overViewImage,
        backgroundImage,
        altbackgroundImage,
        mobilebackgroundImage,
        featuresArr: featureArr,
        technicalSpecificationArr: technicalSpecificationsArr,
        endUseArr: endUseArr,
        image,
        faqArr,
        slug,
        metaImage,
        metaTitle,
        genericName,
        metaDescription,
        canonicalLink,
        brochureFile,
        productVideo: {
          videoUrlOf,
          videoUrl: video,
          name: videoName,
          description: videoDescription,
          videoThumbnail: videoThumbnail,
        },
      }
      let { data: res } = await handleEditProduct(params.id, obj)
      if (res?.success) {
        toastSuccess(res?.message)
      }
      // console.log(obj)
    }
    catch (err) {
      toastError(err)
      console.error(err)
    }
  }
  const handleAttributeValueChange = (e) => {
    console.log("event", e)
    setProductCategoryArr(e.target.value);
    // setCountryId(e.target.value);
  };

  const handleGetAllProductCategories = async () => {
    try {
      console.log("asd a")
      let { data: res } = await getProductCategories();
      if (res?.success) {
        console.log(res?.data)
        setProductCategoriesArr(res?.data);
        handleGetAllStandardCategories(res?.data)

      }
    } catch (err) {
      toastError(err)
    }
  }


  const handleGetAllStandardCategories = async (productCatArr) => {
    try {
      console.log("asd a")
      let { data: res } = await getStandardCategory();
      if (res?.success) {
        console.log(res?.data)
        setStandardCategoryArr(res?.data);
        handleGetProductData(productCatArr, res?.data)
      }
    } catch (err) {
      toastError(err)
    }
  }


  const handleStandardCategoryChecked = (index) => {
    let tempArr = standardCategoryArr
    tempArr[index].checked = !tempArr[index].checked
    setStandardCategoryArr([...tempArr])
    console.log(tempArr, "tempArr")
  }

  const handleStandardChecked = (index, indexX) => {
    let tempArr = standardCategoryArr;
    tempArr[index].standardArr[indexX].checked = !tempArr[index].standardArr[indexX].checked;
    setStandardCategoryArr([...tempArr])
    console.log(tempArr, "tempArr")
  }

  useEffect(() => {
    handleGetAllProductCategories()
  }, [])


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;







  const handleFileSet5 = (value) => {
    setBackgroundImage(value)
  }




  return (
    <form action="#" className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Generic Name <span className="red">*</span>
                </label>
                <input type="text" onChange={(e) => setGenericName(e.target.value)} value={genericName} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Slug
                </label>
                <input type="text" onChange={(e) => setSlug(e.target.value)} value={slug} className="form-control" />
              </div>


              <div className="col-12 col-md-6 mt-1" style={{ display: "flex", flexDirection: "column" }}>
                <label>
                  CATEGORY<span className="red">*</span>
                </label>


                <Select
                  sx={{ width: "85%" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={productCategoryArr}
                  onChange={handleAttributeValueChange}
                  input={<OutlinedInput label="Name" />}
                >
                  {productCategoriesArr.map((el) => (
                    <MenuItem
                      key={el._id}
                      value={el._id}
                    >
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>

              </div>


            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description</h5>
              <div className="col-12 mb-3">

                <ReactQuill
                  value={description}
                  onChange={setdescription}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Overview</h5>
              <div className="col-12 mb-3">
                <ReactQuill
                  value={overView}
                  onChange={setOverView}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Heading <span className="red">*</span>
                </label>
                <input type="text" onChange={(e) => setOverViewHeading(e.target.value)} value={overViewHeading} className="form-control" />
              </div>
              <div className="col-12 col-md-6  mb-3">
                <label>Image GIF <span className="red">*</span></label>
                <FileUpload onFileChange={handleFileSet4} />
              </div>
            </div>


            <div className="border-bottom pb-3 mb-4 row">
              <div className="row">
                <h5 className="col-9 blue-1 mb-4">Feature Info</h5>
                <h5 className="col-3 d-flex justify-content-between blue-1 mb-4">
                  <CustomButton btntype="button" ClickEvent={handleFeatureAdd} isBtn btnName="+" />
                  <CustomButton btntype="button" ClickEvent={handleFeatureRemove} isBtn btnName="-" />
                </h5>
              </div>
              <div className="col-12 mb-3">
                {
                  featureArr && featureArr.length > 0 && featureArr.map((el, index) => {
                    return (

                      <Feature key={index} setFeatureName={setFeatureName} featureimage={el.featureimage} mobilefeatureimage={el.mobilefeatureimage} altfeatureimage={el.altfeatureimage} setFeatureImage={setFeatureImage} setMobileFeatureImage={setMobileFeatureImage} setAltFeatureImage={setAltFeatureImage} setFeatureDescription={setFeatureDescription} featureName={el.featureName} featureDescription={el.featureDescription} index={index + 1} />

                    )
                  })
                }
              </div>
            </div>


            <div className="border-bottom pb-3 mb-4 row">
              <div className="row">
                <h5 className="col-9 blue-1 mb-4">End Use Info</h5>
                <h5 className="col-3 d-flex justify-content-between blue-1 mb-4">
                  <CustomButton btntype="button" ClickEvent={handleEndUseAdd} isBtn btnName="+" />
                  <CustomButton btntype="button" ClickEvent={handleEndUseRemove} isBtn btnName="-" />
                </h5>
              </div>
              <div className="col-12 mb-3">
                {
                  endUseArr && endUseArr.length > 0 && endUseArr.map((el, index) => {
                    return (
                      <EndUse key={index} setEndUseName={setEndUseName} endUseImage={el.image} mobileendUseImage={el.mobileimage} altendUseImage={el.altimage} setEndUseImage={setEndUseImage} setEndUseMobileImage={setEndUseMobileImage} setEndUseAltImage={setEndUseAltImage} setEndUseUrl={setEndUseUrl} endUseUrl={el.url} endUseName={el.name} index={index + 1} />
                    )
                  })
                }
              </div>
            </div>


            <div className="border-bottom pb-3 mb-4 row">
              <div className="row">
                <h5 className="col-9 blue-1 mb-4">Technical Specifications Info</h5>
                <h5 className="col-3 d-flex justify-content-between blue-1 mb-4">
                  <CustomButton btntype="button" ClickEvent={handleTechnicalSpecificationsAdd} isBtn btnName="+" />
                  <CustomButton btntype="button" ClickEvent={handleTechnicalSpecificationsRemove} isBtn btnName="-" />
                </h5>
              </div>
              <div className="col-12 mb-3">
                {
                  technicalSpecificationsArr && technicalSpecificationsArr.length > 0 && technicalSpecificationsArr.map((el, index) => {
                    return (
                      <TechnicalSpecifications key={index} setTechnicalSpecificationCategoryName={setTechnicalSpecificationCategoryName} setSpecificationArr={setSpecificationArr} categoryName={el.categoryName}
                        specificationArr={el.specificationArr} index={index + 1} />
                    )
                  })
                }
              </div>
            </div>



            <div className="border-bottom pb-3 mb-4 row">
              <div className="row">
                <h5 className="col-9 blue-1 mb-4">FAQ</h5>
                <h5 className="col-3 d-flex justify-content-between blue-1 mb-4">
                  <CustomButton btntype="button" ClickEvent={handleFaqAdd} isBtn btnName="+" />
                  <CustomButton btntype="button" ClickEvent={handleFaqRemove} isBtn btnName="-" />
                </h5>
              </div>
              <div className="col-12 mb-3">
                {
                  faqArr && faqArr.length > 0 && faqArr.map((el, index) => {
                    return (
                      <div key={index}>
                        <h5 className="blue-1 mb-3">FAQ {index + 1}</h5>

                        <div className="col-12 col-md-6 mb-3">
                          <label>
                            Heading <span className="red">*</span>
                          </label>
                          <input onChange={(e) => setFaqValues(e.target.value, index, "heading")} value={el?.heading} type="text" className="form-control" />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label>
                            Description <span className="red">*</span>
                          </label>
                          <input onChange={(e) => setFaqValues(e.target.value, index, "description")} value={el?.description} type="text" className="form-control" />
                          {/* <input onChange={(e) => setFeatureName(e.target.value, index - 1)} value={featureName} type="text" className="form-control" /> */}
                        </div>
                      </div>


                    )
                  })
                }
              </div>
            </div>







            <div className="border-bottom pb-3 mb-4 row">
              <div className="row">
                <h5 className="col-9 blue-1 mb-4">SEO</h5>
              </div>
              <div className="col-12 mb-3">

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Meta Title <span className="red">*</span>
                  </label>
                  <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Meta Description <span className="red">*</span>
                  </label>
                  <input onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Canonical Link<span className="red">*</span>
                  </label>
                  <input onChange={(e) => setCanonicalLink(e.target.value)} value={canonicalLink} type="text" className="form-control" />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Meta  Image <span className="red">*</span>
                  </label>
                  <br />
                  <br />
                  {metaImage && `${metaImage}`.includes("base64") ?
                    <img src={metaImage && metaImage != "" ? metaImage.split("@@")[1] : ""} alt="" style={{ height: 100, width: 100 }} srcset="" />
                    :
                    <img src={generateFilePath(metaImage)} style={{ height: 100, width: 100 }} alt="" />
                  }

                  <br />
                  <br />
                  <FileUpload onFileChange={handleFileSet6} />
                </div>

              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Image Info</h5>
              <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                <br />
                <br />

                <div style={{ margin: "15px 0px" }}>
                  {image && `${image}`.includes("base64") ?
                    <img style={{ height: 100, width: 100 }} src={image && image != "" ? image.split("@@")[1] : ""} alt="" srcset="" />
                    :
                    <img style={{ height: 100, width: 100 }} src={generateFilePath(image)} alt="" />
                  }
                </div>

                <br />
                <br />
                <FileUpload onFileChange={handleFileSet1} />
              </div>
              {/* <div className="col-12 mb-3">
                <label>
                  Product Mobile Image<span className="red">*</span>
                </label>

                <br />
                <br />
                {mobileimage && `${mobileimage}`.includes("base64") ?
                  <img src={mobileimage} alt="" style={{ height: 100, width: 100 }} srcset="" />
                  :
                  <img src={generateFilePath(mobileimage)} style={{ height: 100, width: 100 }} alt="" />
                }

                <br />
                <br />
                <FileUpload onFileChange={(val) => setmobileImage(val)} />
              </div> */}
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Product Alt Tag <span className="red">*</span>
                </label>
                <input onChange={(e) => setaltImage(e.target.value)} value={altimage} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label>
                  Product Brochure <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                  brochureFile && brochureFile.includes("base64") ?
                    <a download={"pdfTitle"} href={brochureFile && brochureFile != "" ? brochureFile.split("@@")[1] : ""}>Download selected file</a>
                    :
                    <a href={generateFilePath(brochureFile)} target="_black">View Uploaded file</a>
                }
                <br />
                <br />
                <FileUpload onFileChange={handleFileSet7} />
              </div>

              <div className="col-12 mb-3">
                <label>
                  Product Background Image<span className="red">*</span>
                </label>

                <br />
                <div style={{ margin: "15px 0px" }}>
                  {backgroundImage && `${backgroundImage}`.includes("base64") ?
                    <img style={{ height: 100, backgroundColor: "red" }} src={backgroundImage && backgroundImage != "" ? backgroundImage.split("@@")[1] : ""} />
                    :
                    <img style={{ height: 100 }} src={generateFilePath(backgroundImage)} alt="" />
                  }
                </div>
                <FileUpload onFileChange={handleFileSet5} />
              </div>


              <div className="col-12 mb-3">
                <label>
                  Product Mobile Background Image<span className="red">*</span>
                </label>
                <div>
                  {mobilebackgroundImage && `${mobilebackgroundImage}`.includes("base64") ?
                    <img src={mobilebackgroundImage && mobilebackgroundImage != "" ? mobilebackgroundImage.split("@@")[1] : ""} alt="" style={{ height: 100, width: 100 }} srcset="" />
                    :
                    <img src={generateFilePath(mobilebackgroundImage)} style={{ height: 100, width: 100 }} alt="" />
                  }
                </div>
                <FileUpload onFileChange={(val) => setmobileBackgroundImage(val)} />
              </div>

              <div className="col-12 mb-3">
                <label>
                  Product Background Image Alt Tag<span className="red">*</span>
                </label>
                <input onChange={(e) => setaltBackgroundImage(e.target.value)} value={altbackgroundImage} type="text" className="form-control" />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Video Info</h5>

              <div className="col-12 col-md-12 mb-3">
                <label>
                  Video Url <span className="red">*</span>
                </label>
                <input onChange={(e) => setVideoUrlOf(e.target.value)} value={videoUrlOf} type="text" className="form-control" />
              </div>
              {/* <div className="col-12 mb-3">
                <label>VIDEO</label>
                <FileUpload onFileChange={handleFileSet2} />
              </div> */}
              <div className="col-12 mb-3">
                <label>VIDEO THUMBNAIL</label>
                <FileUpload onFileChange={handleFileSet3} />
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label>
                  Video Name <span className="red">*</span>
                </label>
                <input onChange={(e) => setVideoName(e.target.value)} value={videoName} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label>
                  Video Description <span className="red">*</span>
                </label>
                <input onChange={(e) => setVideoDescription(e.target.value)} value={videoDescription} type="text" className="form-control" />
              </div>
            </div>

            <div className=" pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">Product Standards Info (Please select Product Standards)</h5>
              {
                standardCategoryArr && standardCategoryArr.length > 0 && standardCategoryArr.map(((el, index) => {
                  return (
                    <>
                      <div className="col-12 mb-3">
                        <label>
                          {el.name} <span className="red">*</span>
                        </label>
                        <Checkbox checked={el.checked} onChange={(e) => handleStandardCategoryChecked(index)} />
                      </div>
                      <div className="row d-flex justify-content-end border-bottom">
                        {
                          el.checked && el.standardArr && el.standardArr.length > 0 && el.standardArr.map((ele, indexX) => {
                            return (
                              <div className="col-10 mb-3">
                                <label>
                                  {ele.name} <span className="red">*</span>
                                </label>
                                <Checkbox checked={ele.checked} onChange={(e) => handleStandardChecked(index, indexX)} />
                              </div>
                            )
                          })
                        }
                      </div>
                    </>
                  )
                }))
              }
            </div>

            <CustomButton btntype="button" ClickEvent={handleProductSubmit} isBtn btnName="Update Product" />

          </DashboardBox>
        </div>
      </div >
    </form >
  );
}

export default EditGeneralProduct;
