import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import SideBar from "../components/Sidebar/SideBar";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Category from "../components/Products/Category/Category";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";

import BulkProductUpload from "../components/Products/BulkProductUpload";
import ProductList from "../components/Products/ProductList";
import Config from "../components/Products/Config";
import Profile from "../components/Admin/Profile";
import Blog from "../components/Blog/Blog";
import AddBlog from "../components/Blog/AddBlog";
import BlogCategory from "../components/Blog/BlogCategory";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";
import InHouse from "../components/Order/InhouseOrder/Inhouse";
import Brand from "../components/Products/Brand/Brand";
import AddBrand from "../components/Products/Brand/AddBrand";
import BulkBrandUpload from "../components/Products/Brand/BulkBrandUpload";
import BulkCategoryUpload from "../components/Products/Category/BulkCategoryUpload";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import DeliveryProcess from "../components/Order/DeliveryProcess/DeliveryProcess";
import CancleReason from "../components/Order/CancleReason/CancleReason";
import TrackOrder from "../components/Order/TrackOrder/TrackOrder";
import ContactMail from "../components/ContactMail/ContactMail";
// import ProductReview from "../components/Review/ProductReview";
import ReviewConfig from "../components/Review/ReviewConfig";
import CompanyInfo from "../components/CompanyInfo/CompanyInfo";
import Menus from "../components/Menus/Menus";
import AddMenu from "../components/Menus/AddMenu";
import EditMenu from "../components/Menus/EditMenu";
import MenuItem from "../components/Menus/MenuItem";
import PaymentGateway from "../components/PaymentGateway/PaymentGateway";

import GstSetup from "../components/Tax/GstSetup";
import GstConfig from "../components/Tax/GstConfig";
import Customer from "../components/Customer/Customer";
import CustomerDetail from "../components/Customer/CustomerDetail";

import Banners from "../components/Frontend-CMS/Banners/Banners";
import AddBanner from "../components/Frontend-CMS/Banners/AddBanner";
import States from "../components/States/States";
import ViewCountry from "../components/Country/ViewCountry";
import ViewCity from "../components/City/ViewCity";
import MenuCategories from "../components/MenuCategory/MenuCategories";
import SubCategories from "../components/SubCategory/SubCategories";
import ProductCategories from "../components/ProductCategory/ProductCategories";
import ViewOurServices from "../components/OurServices/ViewOurServices";
import ViewClients from "../components/OurClients/ViewClients";
import SiteDetails from "../components/SiteDetails/SiteDetails";
import ParamountInstruments from "../components/ParamountInstruments/ParamountInstruments";
import ShowBlog from "../components/Blog/ShowBlog";
import ViewClientTestimonials from "../components/ClientTestimonials/ViewClientTestimonials";
import StandardCategory from "../components/StandardCategory/StandardCategory";
import AddStandardCategory from "../components/StandardCategory/AddStandardCategory";
import ViewStandard from "../components/Standard/ViewStandard";
import CategoryWiseProductPosition from "../components/CategoryWiseProductPosition/CategoryWiseProductPosition";
import ProductCategoryClient from "../components/ProductCategoryClient/ProductCategoryClient";
import EditBlog from "../components/Blog/EditBlog";
import EditProduct from "../components/Products/EditProduct/EditProduct";
import AddProductComparision from "../components/ProductComparision/AddProductComparision";
import ViewProductComparision from "../components/ProductComparision/ViewProductComparision";
import ProductReview from "../components/ProductReview/ProductReview";
import ViewSeo from "../components/SeoSection/ViewSeo";
import ViewHomepageBanners from "../components/HomepageBanners/ViewHomepageBanners";



import Lead from "../components/Lead/Lead";
import SiteMapData from "../components/Sitemap/SiteMapData";
import AddSiteMapData from "../components/Sitemap/AddSiteMapData";
import Career from "../components/ContactMail/Career";


export default function AuthorizedRoutes() {
  return (
    <section style={{ backgroundColor: "#ebebeb" }}>
      <div className="row g-0">
        <div className="col-12 col-md-2" style={{ contain: "content" }}>
          <SideBar />
        </div>
        <div className="col-12 col-md-10" style={{ height: "100vh", overflow: "hidden scroll" }}>
          <Header />
          <Routes>
            {/* <Route exact path="/" element={<Login />}></Route> */}
            <Route exact path="/" element={<Dashboard />}></Route>
            <Route exact path="/Product/Category" element={<Category />}></Route>
            <Route exact path="/Standard/Category" element={<StandardCategory />}></Route>
            <Route exact path="/Standard/view" element={<ViewStandard />}></Route>
            <Route exact path="/ProductCategoryClientImage/view" element={<ProductCategoryClient />}></Route>
            <Route exact path="/CategoryWiseProductPosition/manage" element={<CategoryWiseProductPosition />}></Route>
            <Route exact path="/generalData/country" element={<ViewCountry />}></Route>
            <Route exact path="/generalData/menuCategories" element={<MenuCategories />}></Route>
            <Route exact path="/generalData/subCategories" element={<SubCategories />}></Route>
            <Route exact path="/generalData/productCategories" element={<ProductCategories />}></Route>
            <Route exact path="//generalData/ViewHomepageBanners" element={<ViewHomepageBanners />}></Route>
            <Route exact path="/services/viewServices" element={<ViewOurServices />}></Route>
            <Route exact path="/clients/viewClients" element={<ViewClients />}></Route>
            <Route exact path="/siteData/viewsiteData" element={<SiteDetails />}></Route>
            <Route exact path="/paramountInstruments/viewParamountInstruments" element={<ParamountInstruments />}></Route>
            <Route exact path="/clientTestimonials/ViewClientTestimonials" element={<ViewClientTestimonials />}></Route>
            <Route exact path="/generalData/state" element={<States />}></Route>
            <Route exact path="/generalData/city" element={<ViewCity />}></Route>
            <Route exact path="/Product/Brand" element={<Brand />}></Route>
            <Route exact path="/Product/Brand-Create" element={<AddBrand />}></Route>
            <Route exact path="/Product/Attribute" element={<Attribute />}></Route>
            <Route exact path="/Product/Attribute-Value" element={<AttributeValue />}></Route>
            <Route exact path="/Product/EditProduct/:id" element={<EditProduct />}></Route>
            <Route exact path="/Product/AddProduct" element={<AddProduct />}></Route>
            <Route exact path="/Product/Bulk-Product-Upload" element={<BulkProductUpload />}></Route>
            <Route exact path="/Product/Bulk-Category-Upload" element={<BulkCategoryUpload />}></Route>
            <Route exact path="/Product/Bulk-Brand-Upload" element={<BulkBrandUpload />}></Route>
            <Route exact path="/Product/Product-List" element={<ProductList />}></Route>
            <Route exact path="/Product/Config" element={<Config />}></Route>
            <Route exact path="/Admin/Profile" element={<Profile />}></Route>
            <Route exact path="/Product/View-Product-Comparision" element={<ViewProductComparision />}></Route>
            <Route exact path="/Product/Add-Product-Comparision" element={<AddProductComparision />}></Route>
            <Route exact path="/Product/Edit-Product-Comparision/:id" element={<AddProductComparision />}></Route>
            <Route exact path="/Blog/post" element={<Blog />}></Route>
            <Route exact path="/Blog/View-Post/:id" element={<ShowBlog />}></Route>
            <Route exact path="/Blog/post/edit/:id" element={<EditBlog />}></Route>
            <Route exact path="/Blog/post/create" element={<AddBlog />}></Route>
            <Route exact path="/Blog/Category" element={<BlogCategory />}></Route>
            <Route exact path="/Order/Total-Order" element={<TotalOrder />}></Route>
            <Route exact path="/Order/Inhouse-Order" element={<InHouse />}></Route>
            <Route exact path="/Order/Delivery-Process" element={<DeliveryProcess />}></Route>
            <Route exact path="/Order/Cancle-Reason" element={<CancleReason />}></Route>
            <Route exact path="/Order/Track-Order" element={<TrackOrder />}></Route>
            <Route exact path="/Contact-Mail" element={<ContactMail />}></Route>
            <Route exact path="/Career" element={<Career />}></Route>
            <Route exact path="/Seo/View" element={<ViewSeo />}></Route>
            {/* <Route exact path="/Contact-Mail" element={<ContactMail />}></Route> */}
            {/* <Route exact path="/Review/Product-Review" element={<ProductReview />}></Route> */}
            <Route exact path="/Review/Review-Configuration" element={<ReviewConfig />}></Route>
            <Route exact path="/Company-Information" element={<CompanyInfo />}></Route>
            <Route exact path="/Menus" element={<Menus />}></Route>
            <Route exact path="/product/reviews" element={<ProductReview />}></Route>
            <Route exact path="/Menus/Menus-Create" element={<AddMenu />}></Route>
            <Route exact path="/Menus/Menus-Edit" element={<EditMenu />}></Route>
            <Route exact path="/Menus/Menus-Item" element={<MenuItem />}></Route>
            <Route exact path="/Payment-Gateway" element={<PaymentGateway />}></Route>
            <Route exact path="/GST-SETUP" element={<GstSetup />}></Route>
            <Route exact path="/GST-Configuation" element={<GstConfig />}></Route>
            <Route exact path="/Customer-list" element={<Customer />}></Route>
            <Route exact path="/Customer-Create" element={<Customer />}></Route>
            <Route exact path="/Customer-Detail" element={<CustomerDetail />}></Route>
            <Route exact path="/Banners/Banner-Create" element={<AddBanner />}></Route>
            <Route exact path="/Banners" element={<Banners />}></Route>
            <Route exact path="/Sitemap/Add-Item" element={<AddSiteMapData />}></Route>
            <Route exact path="/Sitemap-data" element={<SiteMapData />}></Route>

            <Route exact path="/Lead" element={<Lead />}></Route>
          </Routes>
          <Footer />
        </div>
      </div>
    </section>
  );
}
