import React, { useEffect, useState } from "react";
import Select from "react-select";
import { addOurServicesValue } from "../../services/ourServices.service";
import { addParamountInstrumentsValue } from "../../services/paramountInstruments.service";
import { getProductCategories } from "../../services/productCategories.service";
import {
  addStandardValue,
  updateStandardById,
} from "../../services/Standard.service";
import {
  addStandardCategoryValue,
  getStandardCategory,
} from "../../services/StandardCategory.service";
import CustomButton from "../Utility/Button";
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
export default function AddStandard({
  handleSetSelected,
  data,
  setDataIsAdded,
}) {
  const [name, setName] = useState("");
  const [standardCategoryId, setStandardCategoryId] = useState("");
  const [standardCategoryArr, setstandardCategoryArr] = useState([]);
  const [url, setUrl] = useState("");
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [description, setDescription] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [logo, setLogo] = useState("");

  const handleSubmit = async () => {
    try {
      let obj = {
        name,
        standardCategoryId,
        url,
        description,
        backgroundImage,logo,
      };
      let res;
      if (isBeingEdited) {
        res = await updateStandardById(data._id, obj);
      } else {
        res = await addStandardValue(obj);
      }
      if (res.data.success) {
        toastSuccess(res.data.message);
        setDataIsAdded(true);
        handleClearState();
      }
      console.log(res);
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetStandardCategory = async () => {
    try {
      let { data: res } = await getStandardCategory();
      if (res.success) {
        setstandardCategoryArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetStandardCategory();
    return () => setstandardCategoryArr([]);
  }, []);

  useEffect(() => {
    if (
      data &&
      data.standardCategoryId &&
      standardCategoryArr &&
      standardCategoryArr.length > 0
    ) {
      let standardCategoryObj = standardCategoryArr
        .map((el) => {
          let obj = {
            ...el,
            label: el.name,
            value: el._id,
          };
          return obj;
        })
        .find((el) => el._id == data.standardCategoryId);
    //   console.log(standardCategoryObj, "productCategoryObj");
      setIsBeingEdited(true);
      setName(data.name);
      setUrl(data.url);
      setDescription(data?.description);
      setBackgroundImage(data?.backgroundImage);
      setLogo(data?.logo);
      setStandardCategoryId(standardCategoryObj._id);
      setSelectedOption(standardCategoryObj);
    }
  }, [data, standardCategoryArr]);

  const handleClearState = () => {
    setName("");
    setStandardCategoryId("");
    setUrl("");
    setDescription("")
setBackgroundImage("")
setLogo("")
    setIsBeingEdited(false);
    handleSetSelected();
    setSelectedOption({});
  };

  const handleAttributeValueChange = (e) => {
    setStandardCategoryId(e.value);
    setSelectedOption(e);
  };
  const handleFileSetBackgroundImage = (value) => {
    // console.log(value);
    setBackgroundImage(value);
  };
  const handleFileSetLogo = (value) => {
    // console.log(value);
    setLogo(value);
  };

  return (
    <form action="#" className="form row">
      <div className="col-12">
        <label className="blue-1 fs-12">
          Name <span className="red">*</span>
        </label>
        <input
          value={name}
          onChange={(event) => setName(event.target.value)}
          type="text"
          className="form-control"
        />
      </div>

      <div className="col-12">
        <label className="blue-1 fs-12">
          Standard Category <span className="red">*</span>
        </label>
        <Select
          defaultInputValue={standardCategoryId}
          value={selectedOption}
          onChange={handleAttributeValueChange}
          options={
            standardCategoryArr && standardCategoryArr.length > 0
              ? standardCategoryArr.map((el) => ({
                  ...el,
                  label: el?.name,
                  value: el?._id,
                }))
              : []
          }
        />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          URL <span className="red">*</span>
        </label>
        <input
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          type="text"
          className="form-control"
        />
      </div>

      <div className="col-12">
        <label className="blue-1 fs-12">
          DESCRIPTION <span className="red">*</span>
        </label>
        <input
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          type="text"
          className="form-control"
        />
      </div>

      <div className="col-12">
        <label className="blue-1 fs-12">
          BACKGROUND IMAGE <span className="red">*</span>
        </label>
        <FileUpload onFileChange={handleFileSetBackgroundImage} />
      </div>
    
    
      <div className="col-12">
        <label className="blue-1 fs-12">
          LOGO IMAGE <span className="red">*</span>
        </label>
        <FileUpload onFileChange={handleFileSetLogo} />
      </div>

      <div className="col-12 mt-2">
        <CustomButton
          btntype="button"
          ClickEvent={handleSubmit}
          isBtn
          iconName="fa-solid fa-check"
          btnName="Save"
        />
      </div>
    </form>
  );
}
