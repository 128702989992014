import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { deleteResource, getResource } from "../../services/resources.service";
import { url } from "../../services/url.service";
import { generateFilePath } from "../Utility/utils";



function Blog() {

  const [resourcesArr, setResourcesArr] = useState([]);
  const [displayResourceArr, setDisplayResourceArr] = useState([]);
  const [query, setQuery] = useState("");





  const handleDeleteCategory = async (id) => {
    try {
      let { data: res } = await deleteResource(id);
      if (res.success) {
        console.log(res.data)
        toastSuccess(res.message)
        getAllResources()
      }
    } catch (err) {
      toastError(err)
    }
  }


  const blog_columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Title",
      selector: (row) => row.heading,
      width: "20%",
    },
    {
      name: "Published At",
      grow: 0,
      selector: (row) => `${new Date(row.publishedDate).toDateString()}`,
      width: "10%",
    },
    {
      name: "Created At",
      grow: 0,
      selector: (row) => `${new Date(row.createdAt).toDateString()}`,
      width: "10%",
    },
    {
      name: "Resource Type",
      grow: 0,
      selector: (row) => row.resourceType,
      width: "10%",
    },
    {
      name: "Visible on Homepage",
      grow: 0,
      selector: (row) => row.displayOnHome ? "Yes" : "No",
      width: "15%",
    },
    {
      name: "Has Form",
      grow: 0,
      selector: (row) => row.hasForm ? "Yes" : "No",
      width: "15%",
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => <img src={generateFilePath(row.image)} alt="" />,
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => <ActionIcon
        remove
        editPath={`/Blog/post/edit/${row._id}`}
        edit
        onDeleteClick={(e) => {
          e.preventDefault()
          handleDeleteCategory(row._id)
        }}
        delete
        deletePath="/Blog/post"
        detail
        detailpath={`/Blog/View-Post/${row._id}`}
        isRedirected={true}
        Uniquekey={row.id} />,
      width: "15%",
    },
  ];



  const getAllResources = async () => {
    try {
      let { data: res } = await getResource()
      if (res.success) {
        setResourcesArr(res.data)
        setDisplayResourceArr(res.data)
      }

    } catch (error) {
      console.error(error)
      toastError(error)
    }
  }

  useEffect(() => {
    getAllResources()
    return () => setResourcesArr([])
  }, [])


  const handleSearch = (value) => {
    setQuery(value)
    let tempArr = resourcesArr.filter(el =>
      `${el.heading}`.toLowerCase().includes(`${value}`.toLowerCase()) ||
      `${el.resourceType}`.toLowerCase().includes(`${value}`.toLowerCase())
    )
    setDisplayResourceArr([...tempArr])
  }




  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Blog List</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW BLOG"
                    path="/Blog/post/create"
                  />
                  <SearchBox query={query} handleSearch={handleSearch} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={blog_columns} data={displayResourceArr} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Blog;
