import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getCareer } from "../../services/Career.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { generateFilePath } from "../Utility/utils";

function Career() {
  const [getInTouchEnquiryArr, setGetInTouchEnquiryArr] = useState([]);


  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");



  const handleGetInTouchEnquiry = async () => {
    try {
      let { data: res } = await getCareer()
      if (res.data) {
        setGetInTouchEnquiryArr(res.data);
        setMainArr(res.data);
      }
    } catch (error) {
      toastError(error)
      console.error(error)
    }
  }


  useEffect(() => {
    handleGetInTouchEnquiry()
    return (() => setGetInTouchEnquiryArr([]))
  }, [])





  const filterData = (value) => {
    let tempArr = mainArr;
    tempArr = tempArr.filter(el => (
      (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
      || (el?.mobileNo && `${el?.mobileNo}`.includes(value))
      || (el?.message && el?.message.toLowerCase().includes(`${value}`.toLowerCase()))
    ))
    setGetInTouchEnquiryArr([...tempArr])
    setQuery(value)
  }





  // ======================================================================================
  const mail_columns = [
    {
      name: "SL",
      cell: (row, index) => index + 1,
      sortable: true,
      width: '10%'
    },
    {
      name: "Name",
      cell: (row) => <div>{row.name}</div>,
    },
    {
      name: "Mobile Number",
      cell: (row) => <div>{row.phone}</div>,
    },
    {
      name: "Email",
      cell: (row) => <div>{row.email}</div>,
    },
    {
      name: "City",
      cell: (row) => <div>{row.city}</div>,
    },
    {
      name: "Pincode",
      cell: (row) => <div>{row.pincode}</div>,
    },
    {
      name: "Qualification",
      cell: (row) => <div>{row.qualification}</div>,
    },
    {
      name: "Self description",
      cell: (row) => <div>{row.description}</div>,
    },
    {
      name: "Resume",
      cell: (row) => <div><a href={generateFilePath(row.file)} target="_blank" >Click to view</a></div>,
    },
    {
      name: "Recieved On",
      cell: (row) => `${new Date(row.createdAt).toDateString()}`,
    },
    // {
    //   name: "Action",
    //   cell: (row) => <ActionIcon remove detail detailpath='/Contact-Info' Uniquekey={row.id} />,
    //   width: '15%'
    // },
  ];


  // ======================================================================================

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
            <h5 className="blue-1 m-0">Get In Touch Enquiries</h5>
            <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
          </div>
          <h5 className="blue-1 mb-4"></h5>
          <DashboardTable>
            <DataTable columns={mail_columns} data={getInTouchEnquiryArr} pagination />
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default Career;
