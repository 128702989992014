import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { deleteResource, getResource } from "../../services/resources.service";
import { url } from "../../services/url.service";
import { deleteProductComparision, getProductComparision } from "../../services/ProductComparision.service";

function ViewProductComparision() {
    const [resourcesArr, setProductComparisionArr] = useState([]);
    const [displayResourceArr, setDisplayProductComparisionArr] = useState([]);
    const [query, setQuery] = useState("");


    const handleDeleteProductComparision = async (id) => {
        try {
            let { data: res } = await deleteProductComparision(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                getAllProductComparision()
            }
        } catch (err) {
            toastError(err)
        }
    }


    const blog_columns = [
        {
            name: "SL",
            selector: (row, i) => i + 1,
            sortable: true,
            width: "5%",
        },
        {
            name: "Features",
            selector: (row) => row.featuresArr.reduce((acc, el, i) => acc + `${el?.name} ${row.featuresArr.length - 1 != i ? ", " : ""}`, ""),
            width: "20%",
        },
        {
            name: "Products",
            grow: 0,
            selector: (row) => row.productIdArr.reduce((acc, el, i) => acc + `${el?.productName} ${row.productIdArr.length - 1 != i ? ", " : ""}`, ""),
            width: "20%",
        },
        {
            name: "Action",
            cell: (row) => <ActionIcon
                remove
                editPath={`/Product/Edit-Product-Comparision/${row._id}`}
                edit
                onDeleteClick={(e) => {
                    e.preventDefault()
                    handleDeleteProductComparision(row._id)
                }}
                delete
                deletePath="/Product/View-Product-Comparision"
                isRedirected={true}
                Uniquekey={row._id} />,
            width: "15%",
        },
    ];



    const getAllProductComparision = async () => {
        try {
            let { data: res } = await getProductComparision()
            if (res.success) {
                setProductComparisionArr(res.data)
                setDisplayProductComparisionArr(res.data)
            }

        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        getAllProductComparision()
        return () => setProductComparisionArr([])
    }, [])


    const handleSearch = (value) => {
        setQuery(value)
        let tempArr = resourcesArr.filter(el =>
            `${el.heading}`.toLowerCase().includes(`${value}`.toLowerCase()) ||
            `${el.resourceType}`.toLowerCase().includes(`${value}`.toLowerCase())
        )
        setDisplayProductComparisionArr([...tempArr])
        console.log(value)
    }

    return (
        <main>
            <section className="product-category" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h5 className="blue-1 m-0">Product Comparision List</h5>
                                <div className="d-flex gap-3">
                                    <CustomButton
                                        isLink
                                        iconName="fa-solid fa-plus"
                                        btnName="ADD NEW PRODUCT COMPARISION"
                                        path="/Product/Add-Product-Comparision"
                                    />
                                    {/* <SearchBox query={query} handleSearch={handleSearch} extraClass="bg-white" /> */}
                                </div>
                            </div>
                            <DashboardTable>
                                <DataTable columns={blog_columns} data={displayResourceArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default ViewProductComparision;
