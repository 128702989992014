import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/productComparision";

export const addProductComparision = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getProductComparision = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};


export const getProductComparisionById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
export const updateProductComparision = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};

export const deleteProductComparision = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
