import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, updateMenuCategory } from "../../services/menuCategories.service";
export default function AddMenuCategory({ handleSetSelected, data }) {

    const [countryId, setCountryId] = useState("");

    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");


    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                slug
            };
            let res
            if (isBeingEdited) {
                res = await updateMenuCategory(data._id, obj)
            }
            else {
                res = await addMenuCategoriesValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };


    const handleClearState = () => {
        setName("")
        setSlug("")
        setIsBeingEdited(false)
        handleSetSelected()
    }


    useEffect(() => {
        if (data && data.name) {
            setName(data.name)
            setSlug(data.slug ? data.slug : "")
            setIsBeingEdited(true)
        }
    }, [data])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Slug <span className="red">*</span>
                </label>
                <input value={slug} onChange={(event) => setSlug(event.target.value)} type="text" className="form-control" />
            </div>


            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
