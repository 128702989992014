import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getmenuCategories } from "../../services/menuCategories.service";
import { addProductCategoriesValue, updateproductCategory } from "../../services/productCategories.service";
import { addSubCategoriesValue, getSubCategories } from "../../services/subCategories.service";
import { url } from "../../services/url.service";
import CustomButton from "../Utility/Button";
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
export default function AddProductCategory({ handleSetSelected, data, setDataIsAdded }) {

    const [subCategoryId, setSubCategoryId] = useState("");

    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});

    const [name, setName] = useState("");
    const [subCategoriesArr, setsubCategoriesArr] = useState([]);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [mobileimage, setmobileImage] = useState("");
    const [altimage, setaltImage] = useState("");
    const [bannerImage, setBannerImage] = useState("");
    const [mobilebannerImage, setmobileBannerImage] = useState("");
    const [bottomImage, setBottomImage] = useState("");
    const [altbottomImage, setaltBottomImage] = useState("");
    const [mobilebottomImage, setmobileBottomImage] = useState("");


    const [description2, setDescription2] = useState("");
    const [slug, setSlug] = useState("");
    const [brochureFile, setBrochureFile] = useState("");

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                subCategoryId,
                description,
                image,
                altimage,
                mobileimage,
                bannerImage,
                mobilebannerImage,
                description2,
                slug,
                bottomImage,
                mobilebottomImage,
                altbottomImage,
                brochureFile: brochureFile,
            };
            let res


            console.log(obj)
            if (isBeingEdited) {
                res = await updateproductCategory(data._id, obj)
            }
            else {
                res = await addProductCategoriesValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true);
                handleClearState()
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };

    const handleGetAllSubCategories = async () => {
        try {
            let { data: res } = await getSubCategories();
            if (res.success) {
                console.log(res.data)
                setsubCategoriesArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleAttributeValueChange = (e) => {
        console.log("event", e?.value)
        setSubCategoryId(e.value);
        // setCountryId(e.target.value);
    };

    useEffect(() => {
        handleGetAllSubCategories()
    }, [])




    useEffect(() => {
        if (data && data.name && subCategoriesArr && subCategoriesArr.length > 0) {
            setName(data?.name);
            setSlug(data?.slug ? data.slug : "");
            setBottomImage(data?.bottomImage);
            setaltBottomImage(data?.altbottomImage);
            setmobileBottomImage(data?.mobilebottomImage);
            setImage(data?.image);
            setmobileImage(data?.mobileimage);
            setaltImage(data?.altimage);
            setBannerImage(data?.bannerImage);
            setmobileBannerImage(data?.mobilebannerImage);
            setBrochureFile(data.brochureFile);
            console.log(data, "daa")
            let SubCategoryObj = subCategoriesArr.map(el => {
                let obj = {
                    ...el,
                    label: el.name,
                    value: el._id,
                }
                return obj
            }).find(el => el._id == data.subCategoryId)
            setIsBeingEdited(true)
            setDescription(data.description ? data.description : "")
            console.log(SubCategoryObj, "SubCategoryObj")
            setSubCategoryId(SubCategoryObj?._id)
            setSelectedOption(SubCategoryObj)
            setDescription2(data?.description2)
        }
    }, [data, subCategoriesArr])

    const handleClearState = () => {
        setName("");
        setSlug("");
        setIsBeingEdited(false);
        handleSetSelected();
        setSubCategoryId("");
        setBrochureFile("");
        setSelectedOption({});
    }


    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Slug <span className="red">*</span>
                </label>
                <input value={slug} onChange={(event) => setSlug(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description for bottom section<span className="red">*</span>
                </label>
                <input value={description2} onChange={(event) => setDescription2(event.target.value)} type="text" className="form-control" />
            </div>

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    image && image != "" && image.includes("base64") ?
                        <img src={image && image != "" ? image.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(image)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setImage(e)} />
            </div>

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Mobile  Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    mobileimage && mobileimage != "" && mobileimage.includes("base64") ?
                        <img src={mobileimage && mobileimage != "" ? mobileimage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(mobileimage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setmobileImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Alt Tag Image <span className="red">*</span>
                </label>
                <input value={altimage} onChange={(event) => setaltImage(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Banner Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    bannerImage && bannerImage != "" && bannerImage.includes("base64") ?
                        <img src={bannerImage && bannerImage != "" ? bannerImage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(bannerImage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setBannerImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Mobile  Banner Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    mobilebannerImage && mobilebannerImage != "" && mobilebannerImage.includes("base64") ?
                        <img src={mobilebannerImage && mobilebannerImage != "" ? mobilebannerImage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(mobilebannerImage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setmobileBannerImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Bottom Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    bottomImage && bottomImage != "" && bottomImage.includes("base64") ?
                        <img src={bottomImage && bottomImage != "" ? bottomImage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(bottomImage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setBottomImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Mobile Bottom Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    mobilebottomImage && mobilebottomImage != "" && mobilebottomImage.includes("base64") ?
                        <img src={mobilebottomImage && mobilebottomImage != "" ? mobilebottomImage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(mobilebottomImage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setmobileBottomImage(e)} />
            </div>

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Alt Tag Bottom Image <span className="red">*</span>
                </label>
                <input value={altbottomImage} onChange={(event) => setaltBottomImage(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Brochure file <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    brochureFile && brochureFile != "" && brochureFile.includes("base64") ?
                        <img src={brochureFile && brochureFile != "" ? brochureFile.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(brochureFile)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setBrochureFile(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">Sub Category</label>
                <Select defaultInputValue={subCategoryId} value={selectedOption} onChange={handleAttributeValueChange} options={subCategoriesArr && subCategoriesArr?.length > 0 ? subCategoriesArr.map((el) => ({ ...el, label: el?.name, value: el?._id })) : []} />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
