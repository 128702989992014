import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteParamountInstruments, getParamountInstruments } from "../../services/paramountInstruments.service";
import { deleteStandardCategoryById, getStandardCategory } from "../../services/StandardCategory.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddStandardCategory from "./AddStandardCategory";
import { generateFilePath } from "../Utility/utils";
export default function StandardCategory() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [standardCategoryArr, setstandardCategoryArr] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selected, setSelected] = useState({});
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");




    const handleGetStandardCategory = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getStandardCategory();
            if (res.success) {
                // console.log(res.data)
                setstandardCategoryArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteStandardCategory = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deleteStandardCategoryById(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetStandardCategory()
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleSetSelected = () => {
        setSelected({})
        setIsEditActive(false)
        handleGetStandardCategory()
    }




    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },

        {
            name: "Image",
            cell: (row) => <img src={generateFilePath(row.logo)} style={{ width: "100px", height: "100px" }} alt="" />,
        },
        {
            name: "Description",
            cell: (row) => row?.description,
        },
        {
            name: "BackGround Image",
            cell: (row) => <img src={generateFilePath(row.backgroundImage)} style={{ width: "100px", height: "100px" }} alt="" />,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteStandardCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setIsEditActive(true)
                            setSelected(row)
                        }}
                    />
                    {/* <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" /> */}
                </>
            ),
        },
    ];

    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            (el?.name && el?.name.toLowerCase().includes(`${value}`.toLowerCase()))
        ))
        setstandardCategoryArr([...tempArr])
        setQuery(value)
    }




    useEffect(() => {
        handleGetStandardCategory()
        return () => setstandardCategoryArr([])
    }, [dataIsAdded])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"}  Standard Category</h5>
                            </div>
                            <DashboardBox>
                                <AddStandardCategory handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Standard Category Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={standardCategoryArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
