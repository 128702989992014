import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/product";


export const addProduct = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/`, obj);
};


export const getProducts = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const getProductById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getSingleProductById/${id}`);
};
export const getProductsNotYetCompared = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/getProductsNotYetCompared?${query}`);
};


export const DeleteProductById = async (id, obj) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const RemoveProductCategoryByProductId = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/removeCategory/${id}`, obj);
};
export const AddProductCategoryByProductId = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/AddCategory/${id}`, obj);
};


export const handleSaveProductsPositions = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/saveProductsPositionsByProductCategoryId/${id}`, obj);
};
export const handleEditProduct = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/editProduct/${id}`, obj);
};

