import React, { useEffect, useState } from "react";
import Select from '@mui/material/Select';
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import { addResourceValue } from "../../services/resources.service";
import { toastError } from "../Utility/ToastUtils";
import { toastSuccess } from "../../utils/toastUtils";
import { getProductsNotYetCompared } from "../../services/product.service";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { addProductComparision, getProductComparisionById, updateProductComparision } from "../../services/ProductComparision.service";
import { useNavigate, useParams } from "react-router-dom";

function AddProductComparision() {
    const [productsArr, setProductsArr] = useState([]);
    const [featureArr, setFeatureArr] = useState([]);
    const params = useParams()

    const navigate = useNavigate()

    const [isEditingTrue, setIsEditingTrue] = useState(false);
    const [selectedProductsArr, setSelectedProductsArr] = useState([]);
    const [displaySelectedArr, setDisplaySelectedArr] = useState([]);

    const handleAttributeValueChange = (e) => {
        setSelectedProductsArr(e.target.value);
        let tempArr = e.target.value.map(el => {
            let obj = {
                productId: el,
                featuresArr: featureArr.map((el) => {
                    let obj2 = {
                        description: ""
                    }
                    return obj2
                })
            }
            return obj
        })
        setDisplaySelectedArr([...tempArr])
    };




    const handleSave = async () => {
        try {
            let obj = {
                featuresArr: featureArr,
                productIdArr: displaySelectedArr,
            }
            let res = {}
            if (params.id) {
                res = await updateProductComparision(params.id, obj)
            }
            else {
                res = await addProductComparision(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                navigate(-1);
            }

        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }



    useEffect(() => {


        if (featureArr && featureArr.length && selectedProductsArr && selectedProductsArr.length) {
            let tempArr = selectedProductsArr.map(el => {
                let obj = {
                    productId: el,
                    featuresArr: featureArr.map((ele, indexValue) => {
                        let tempDescription = "";
                        let tempProduct = displaySelectedArr.find((elx, indexx) => elx.productId == el);
                        if (tempProduct) {
                            let tempFeature = tempProduct.featuresArr.find((elm, innerIndex) => innerIndex == indexValue);
                            if (tempFeature) {
                                tempDescription = tempFeature.description
                            }

                        }
                        let obj2 = {
                            description: tempDescription
                        }
                        return obj2
                    })
                }
                return obj
            })
            setDisplaySelectedArr([...tempArr])
        }


    }, [featureArr, selectedProductsArr])


    const handleFeatureAdd = () => {
        let fArr = [...featureArr, {
            name: "",
        }]
        setFeatureArr([...fArr])




    }
    const handleFeatureRemove = () => {
        if (featureArr.length > 1) {
            let tempArr = featureArr.filter((el, index) => index != featureArr.length - 1)
            setFeatureArr([...tempArr])
            handleAttributeValueChange()
        }
    }



    const HandleGetProductsNotYetCompared = async (productIdArr) => {
        try {
            let query = {}
            if (productIdArr && productIdArr.length > 0) {
                query = `productIdArr=${[...productIdArr]} `
            }
            //console.log(query, "query")
            let res = {}
            if (productIdArr && productIdArr.length > 0) {
                res = await getProductsNotYetCompared(query)
            } else {
                res = await getProductsNotYetCompared()
            }
            if (res.data.success) {
                if (productIdArr && productIdArr.length > 0) {
                    setSelectedProductsArr([...productIdArr])
                }
                setProductsArr(res.data.data);
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }




    const handleGetProductComparisionById = async () => {
        try {
            let { data: res } = await getProductComparisionById(params.id)
            if (res.success) {
                //console.log(res.data)
                setFeatureArr(res.data.featuresArr)
                if (res.data.productIdArr && res.data.productIdArr.length) {

                    HandleGetProductsNotYetCompared(res.data.productIdArr.map(el => el?.productId))
                    let tempArr = res.data.productIdArr.map(el => {
                        let obj = {
                            productId: el?.productId,
                            featuresArr: el?.featuresArr
                        }
                        return obj
                    })
                    // alert("Asd")
                    setDisplaySelectedArr([...tempArr])
                }


                // setProductsArr(res.data);
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }


    useEffect(() => {
        if (params.id) {
            setIsEditingTrue(true)
            handleGetProductComparisionById()
            console.log("asdf1")
        }
        else {
            setIsEditingTrue(false)
            console.log("asdf")
            HandleGetProductsNotYetCompared()
        }
        return () => {
            setProductsArr([])
        }

    }, [params])


    ////////setting features
    const setName = (value, indexValue) => {
        setFeatureArr(previousValue => {
            previousValue[indexValue].name = value
            return [...previousValue]
        })
    }

    const setFeaturesArr = (value, indexValue) => {
        setDisplaySelectedArr(previousValue => {
            previousValue[indexValue].featuresArr = value
            return [...previousValue]
        })
    }

    const setFeaturesArrData = (value, index) => {
        let tempArr = displaySelectedArr;
        tempArr[index].featuresArr = value
        setDisplaySelectedArr([...tempArr])
    }

    return (
        <main>
            <section className="product-category" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <form action="#" className="form">
                        <h5 className="blue-1 mb-4">{isEditingTrue ? "Edit " : "Add New"} Product Comparision</h5>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <DashboardBox>

                                    <div className="border-bottom mt-5 pb-3 mb-4 row">
                                        <div className="row">
                                            <h5 className="col-10 blue-1 mb-4">Feature Info</h5>
                                            <h5 className="col-2 d-flex justify-content-between blue-1 mb-4">
                                                <CustomButton noIcon btntype="button" ClickEvent={handleFeatureAdd} isBtn btnName="+" />
                                                <CustomButton noIcon btntype="button" ClickEvent={handleFeatureRemove} isBtn btnName="-" />
                                            </h5>
                                        </div>
                                        <div className="col-12 mb-3">
                                            {
                                                featureArr && featureArr.length > 0 && featureArr.map((el, index) => {
                                                    return (
                                                        <Feature key={index} setName={setName} name={el.name} index={index + 1} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>





                                    <div className="row">
                                        <h5 className="blue-1 mb-4">Product Comparision Info</h5>
                                        <div className="col-12 col-md-12 mt-1" style={{ display: "flex", flexDirection: "column" }}>
                                            <label>
                                                Products<span className="red">*</span>
                                            </label>
                                            <Select
                                                sx={{ width: "100%" }}
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={selectedProductsArr}
                                                onChange={handleAttributeValueChange}
                                                input={<OutlinedInput label="Products" />}
                                            >
                                                {productsArr.map((el) => {
                                                    return (
                                                        <MenuItem
                                                            key={el._id}
                                                            value={el._id}
                                                        >
                                                            {el.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>

                                        </div>
                                        <div className="col-4 mb-3">
                                            {
                                                displaySelectedArr && displaySelectedArr.length > 0 && displaySelectedArr.map((el, index) => {
                                                    return (
                                                        <FeatureDescription key={index} setFeaturesArr={(value) => setFeaturesArrData(value, index)} featuresArr={el.featuresArr} i={index} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </DashboardBox>
                            </div>
                        </div>
                        <CustomButton noIcon btntype="button" ClickEvent={handleSave} isBtn btnName="Save" />


                    </form>
                </div>
            </section>
        </main>
    );
}


export const Feature = ({ index, name, setName, }) => {
    return (
        <>
            <h5 className="blue-1 mb-3">Feature {index}</h5>
            <div className="col-12 col-md-6 mb-3">
                <label>
                    Feature Name <span className="red">*</span>
                </label>
                <input onChange={(e) => setName(e.target.value, index - 1)} value={name} type="text" className="form-control" />
            </div>
        </>
    )
}



export const FeatureDescription = ({ featuresArr, setFeaturesArr, i }) => {

    const setDescription = (value, indexValue) => {
        let tempArr = featuresArr
        tempArr[indexValue].description = value
        setFeaturesArr([...tempArr])
    }


    // const featureAdd = 



    return (
        <div className="border-bottom mt-5 pb-3 mb-4 row">
            <div className="row">
                <h5 className="col-10 blue-1 mb-4">Product {i + 1}</h5>
                {/* <h5 className="col-2 d-flex justify-content-between blue-1 mb-4">
                    <CustomButton noIcon btntype="button" ClickEvent={handleFeatureAdd} isBtn btnName="+" />
                    <CustomButton noIcon btntype="button" ClickEvent={handleFeatureRemove} isBtn btnName="-" />
                </h5> */}
            </div>
            {
                featuresArr && featuresArr.length > 0 && featuresArr.map((el, indexX) => {
                    return (

                        <div key={indexX}>
                            <h5 className="blue-1 mb-3">Feature {indexX + 1} </h5>
                            <div className="col-12 col-md-6 mb-3">
                                <label>
                                    Feature Description <span className="red">*</span>
                                </label>
                                <input onChange={(e) => setDescription(e.target.value, indexX)} value={el.description} type="text" className="form-control" />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default AddProductComparision;