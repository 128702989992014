import React, { useEffect, useState } from "react";
import Select from "react-select";
import { addOurServicesValue } from "../../services/ourServices.service";
import { addParamountInstrumentsValue, updateParamountInstruments } from "../../services/paramountInstruments.service";
import { getProductCategories } from "../../services/productCategories.service";
import CustomButton from "../Utility/Button";
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
export default function AddParamountInstruments({ handleSetSelected, data, setDataIsAdded }) {



    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                image: image
            };
            let res
            if (isBeingEdited) {
                if (image == "") {
                    delete obj.image
                }
                res = await updateParamountInstruments(data._id, obj)
            }
            else {
                res = await addParamountInstrumentsValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };



    useEffect(() => {
        if (data && data?.name) {
            setName(data.name);
            setDescription(data.description);
            setIsBeingEdited(true);
        }
    }, [data])


    const handleClearState = () => {
        setName("");
        setImage("");
        setDescription("");
        setIsBeingEdited(false);
        handleSetSelected();
    }



    const handleFileSet = (value) => {
        // console.log(value);
        setImage(value);
    };


    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image <span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>


            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
