import React, { useState } from "react";
import DataTable from "react-data-table-component";
import AddAttribute from "./AddAttribute";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import SearchBox from "../../Utility/SearchBox";

function Attribute() {
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const product_sale_columns = [
    {
      name: "SL",
      selector: (row) => row.sl,
      sortable: true,
    },
    {
      name: "ATTRIBUTE NAME",
      selector: (row) => row.Name,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.desp,
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => <CustomButton greenBtn noIcon btnName="Active" path={row.url} />,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            edit
            detail
            detailClick={(e) => {
              e.preventDefault();
              setModalBox(true);
              setModalType("show-attribute");
              setModalName("Show Attribute");
            }}
          />
          <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="min-content" />
        </>
      ),
    },
  ];

  const product_sale_data = [
    {
      id: "1",
      sl: "1",
      Name: "Nails",
      desp: "Lips",
      url: "/",
    },
    {
      id: "2",
      sl: "2",
      Name: "Eyes",
      desp: "Eyes",
      url: "/",
    },
    {
      id: "3",
      sl: "3",
      Name: "Face",
      desp: "Eyes",
      url: "/",
    },
    {
      id: "4",
      sl: "4",
      Name: "Lips",
      desp: "Lips",
      url: "/",
    },
    {
      id: "5",
      sl: "5",
      Name: "Nail polish",
      desp: "Lips",
      url: "/",
    },
    {
      id: "6",
      sl: "6",
      Name: "Perfect Finish box(Nail P...",
      desp: "Face",
      url: "/",
    },
    {
      id: "7",
      sl: "7",
      Name: "Foundation",
      desp: "Face",
      url: "/",
    },
    {
      id: "8",
      sl: "8",
      Name: "LIQUID SINDOOR",
      desp: "Nails",
      url: "/",
    },
    {
      id: "9",
      sl: "9",
      Name: "BEAUTY POP BOX LIP COLOR",
      desp: "Nails",
      url: "/",
    },
    {
      id: "10",
      sl: "10",
      Name: "LIPSTIC A & B",
      desp: "Parent",
      url: "/",
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Add New Attribute</h5>
                <CustomButton isLink iconName="fa-solid fa-plus" btnName="Add Attribute Value" path="/Product/Attribute-Value" small />
              </div>
              <DashboardBox>
                <AddAttribute />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                <h5 className="blue-1 m-0">Attribute Lists</h5>
                <SearchBox extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={product_sale_data} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Attribute;
