import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/standard";

export const addStandardValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getStandard = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteStandardById = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateStandardById = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj);
};

