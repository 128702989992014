import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { getCountry } from "../../services/Country.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addStateValue, updateState } from "../../services/State.service";
export default function AddState({ handleSetSelected, data }) {

    const [countryId, setCountryId] = useState("");
    const [countryArr, setCountryArr] = useState([]);
    const [name, setName] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                countryId
            };
            let res = {}

            if (isBeingEdited) {
                res = await updateState(data._id, obj)
            }
            else {
                res = await addStateValue(obj);
            }

            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
                console.log(res.data)
            }


        }
        catch (err) {
            console.error(err)
        }
    };


    const handleClearState = () => {
        setName("")
        setCountryId("")
        setIsBeingEdited(false)
        handleSetSelected()
        setSelectedOption({})
    }



    const handleAttributeValueChange = (e) => {
        console.log("event", e)
        setSelectedOption(e)
        setCountryId(e.value);
        // setCountryId(e.target.value);
    };

    const handleGetAllCountries = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getCountry();
            if (res.success) {
                console.log(res.data)
                setCountryArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        if (data && data.name && countryArr && countryArr.length > 0) {
            setName(data.name)
            console.log(data.countryId, "daa")
            let countryObj = countryArr.map(el => {
                let obj = {
                    ...el,
                    label: el.name,
                    value: el._id,
                }
                return obj
            }).find(el => el._id == data.countryId)
            console.log(countryObj, "countryObj")
            setCountryId(countryObj)
            setSelectedOption(countryObj)
            setIsBeingEdited(true)
        }
    }, [data, countryArr])


    useEffect(() => {
        handleGetAllCountries()
        return () => setCountryArr([])
    }, [])



    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name<span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">Country</label>
                <Select defaultInputValue={countryId} value={selectedOption} onChange={handleAttributeValueChange} options={countryArr && countryArr.length > 0 ? countryArr.map((el) => ({ ...el, label: el?.name, value: el?._id })) : []} />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
