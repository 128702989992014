import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toastError } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import AddState from "./AddState";
import { deleteStateById, getState } from "../../services/State.service";

export default function States() {
  const [stateArr, setStateArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");
  const getStateData = async () => {
    try {
      const { data: res } = await getState();
      if (res) {
        setStateArr(res.data);
        setMainArr(res.data);
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  const deleteState = async (id) => {
    try {
      const { data: res } = await deleteStateById(id);
      if (res) {
        getStateData();
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  useEffect(() => {
    getStateData();
    return () => setStateArr([])
  }, []);

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [selected, setSelected] = useState({});


  const handleSetSelected = () => {
    setSelected({})
    getStateData()
    setIsEditActive(false)
  }


  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "State Name",
      selector: (row) => row.name,
    },
    {
      name: "Country Name",
      selector: (row) => row?.countryObj?.name,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            edit
            isRedirected={true}
            editPath="/generalData/state"
            deletePath="/generalData/state"
            onDeleteClick={(e) => {
              e.preventDefault();
              deleteState(row._id);
            }}
            onEditClick={(e) => {
              e.preventDefault();
              setIsEditActive(true)
              setSelected(row)
            }}
          />
          <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="min-content" />
        </>
      ),
    },
  ];






  const filterData = (value) => {
    let tempArr = mainArr;
    tempArr = tempArr.filter(el => (

      el.name.toLowerCase().includes(`${value}`.toLowerCase())
      || el?.countryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
    )
    )
    setStateArr([...tempArr])
    setQuery(value)
  }








  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} State</h5>
              </div>
              <DashboardBox>
                <AddState handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                <h5 className="blue-1 m-0">State Lists</h5>
                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={stateArr} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
