
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/productReview";

export const getProductReview = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const updateProductReview = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};