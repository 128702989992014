import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue } from "../../services/subCategories.service";
import { addOurServicesValue, updateourServices } from "../../services/ourServices.service";
import { getProductCategories } from "../../services/productCategories.service";
import FileUpload from "../Utility/FileUpload";
export default function AddOurServices({ handleSetSelected, data, setDataIsAdded }) {

    const [productCategoryId, setProductCategoryId] = useState("");

    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});

    const [name, setName] = useState("");
    const [logo, setLogo] = useState("");
    const [productCategoriesArr, setProductCategoriesArr] = useState([]);
    const [description, setDescription] = useState("");

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                productCategoryId,
                logo,
                description,
            };

            let res
            if (isBeingEdited) {
                res = await updateourServices(data._id, obj)
            }
            else {
                res = await addOurServicesValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true)
                handleClearState()
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };

    useEffect(() => {
        if (data && data.name && productCategoriesArr && productCategoriesArr.length > 0) {
            setName(data.name);
            console.log(data.productCategoryId, "dat");
            console.log(productCategoriesArr, "daat");
            console.log(productCategoriesArr.map(el => ({ productCategoryId: el._id })), "daaat")
            let productCategoryObj = productCategoriesArr.map(el => {
                let obj = {
                    ...el,
                    label: el.name,
                    value: el._id,
                }
                return obj
            }).find(el => el._id == data.productCategoryId);
            setIsBeingEdited(true);
            setDescription(data.description ? data.description : "");
            console.log(productCategoryObj, "SubCategoryObj");
            setProductCategoryId(productCategoryObj?._id);
            setSelectedOption(productCategoryObj);
        }
    }, [data, productCategoriesArr])

    const handleClearState = () => {
        setName("");
        setDescription("");
        setLogo("");
        setProductCategoryId("");
        setIsBeingEdited(false);
        handleSetSelected();
        setSelectedOption({});
    }



    const handleGetAllProductCategories = async () => {
        try {
            let { data: res } = await getProductCategories();
            if (res.success) {
                console.log(res.data)
                setProductCategoriesArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleAttributeValueChange = (e) => {
        setProductCategoryId(e.value);
        setSelectedOption(e);
    };


    const handleFileSet = (value) => {
        setLogo(value);
    };


    useEffect(() => {
        handleGetAllProductCategories()
    }, [])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image <span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">Product Category
                    <span className="red">*</span>
                </label>
                <Select defaultInputValue={productCategoryId} value={selectedOption} onChange={handleAttributeValueChange} options={productCategoriesArr && productCategoriesArr.length > 0 ? productCategoriesArr.map((el) => ({ ...el, label: el?.name, value: el?._id })) : []} />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
