import React, { useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthorizedRoutes from "./AuthorizedRoutes";

import { useSelector, useDispatch } from "react-redux";
import UnauthorizedRoutes from "./UnauthorizedRoutes";
import { axiosApiInstance } from "../App";
import { url } from "../services/url.service";
import { toastError } from "../utils/toastUtils";
import { logoutUser } from "../redux/actions/auth/auth.actions";

export default function RootRouter() {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useMemo(() => {
    axiosApiInstance.interceptors.request.use(
      (config) => {
        if (authObj.token) {
          config.headers["authorization"] = "Bearer " + authObj.token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        // if (err?.response?.status === 401) {
        //   // console.log("ONLY IN ERROR")
        //   // toast.error('401 Unauthorized')
        //   localStorage.removeItem('AUTH_TOKEN')
        //   setIsAuthorized(false)
        // }
        // if(err?.response?.data?.message){
        //     toast.error(err?.response?.data?.message)
        // }
        // else{
        //     toast.error(err.message)
        // }
        // return Promise.reject(err);

        if (originalConfig.url !== `${url}users/login/` && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            // let authToken = localStorage.getItem('AUTH_TOKEN')
            // localStorage.removeItem('AUTH_TOKEN')
            // originalConfig._retry = true;

            // try {
            //   let token = localStorage.getItem('AUTH_REFRESH_TOKEN')
            //   const rs = await axios.get(`${url}users/generateNewAccessToken/${token}`)
            //   console.log(rs.data)
            //   if (rs.data.data) {
            //     localStorage.setItem('AUTH_TOKEN', rs.data.data)
            //     return axiosApiInstance(originalConfig)
            //   }
            //   else {
            //     throw new Error(err);

            //   }

            // } catch (error) {
            //   // localStorage.removeItem('AUTH_TOKEN')
            //   // localStorage.removeItem('AUTH_REFRESH_TOKEN')
            //   // setIsAuthorized(false)

            //   return Promise.reject(error);
            // }
            dispatch(logoutUser());
          }
        }
        toastError(err);
        return Promise.reject(err);
      }
    );
  }, [authObj]);

  return <Router>{authObj?.isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</Router>;
}
