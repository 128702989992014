import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

function SideBar() {
  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    // {
    //   isrotated: true,
    //   active: true,
    //   name: "dashboard",
    //   path: "/Dashboard",
    //   icon: "ion-grid",
    //   children: [],
    // },

    {
      isrotated: false,
      active: false,
      name: "General data",
      path: "/Dashboard",
      icon: "fa-solid fa-database",
      children: [
        {
          name: "Country",
          path: "/generalData/country",
          active: false,
        },
        {
          name: "State",
          path: "/generalData/state",
          active: false,
        },
        {
          name: "City",
          path: "/generalData/city",
          active: false,
        },
        {
          name: "Menu Categories",
          path: "/generalData/menuCategories",
          active: false,
        },
        {
          name: "Sub Categories",
          path: "/generalData/subCategories",
          active: false,
        },
        {
          name: "Product Categories",
          path: "/generalData/productCategories",
          active: false,
        },
        {
          name: "Homepage banners",
          path: "/generalData/ViewHomepageBanners",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Our Services",
      path: "/services/viewServices",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Product Category Client Image",
      path: "/ProductCategoryClientImage/view",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "products",
      path: "/Dashboard",
      icon: "fa-brands fa-product-hunt",
      children: [


        {
          name: "Standard Category",
          path: "/Standard/Category",
          active: false,
        },
        {
          name: "Standard",
          path: "/Standard/view",
          active: false,
        },
        // {
        //   name: "Attribute",
        //   path: "/Product/Attribute",
        //   active: false,
        // },
        // {
        //   name: "Add New Product",
        //   path: "/Product/AddProduct",
        //   active: false,
        // },
        // {
        //   name: "Bulk Product Upload",
        //   path: "/Product/Bulk-Product-Upload",
        //   active: false,
        // },
        {
          name: "Product List",
          path: "/Product/Product-List",
          active: false,
        },
        {
          name: "Category Wise Product Position Management",
          path: "CategoryWiseProductPosition/manage",
          active: false,
        },
        {
          name: "Product Comparision Management",
          path: "/Product/View-Product-Comparision",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Our Clients",
      path: "/clients/viewClients",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Product Review",
      path: "/product/reviews",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Client Testimonials",
      path: "/clientTestimonials/ViewClientTestimonials",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Site Data",
      path: "/siteData/viewsiteData",
      icon: "fa-solid fa-user",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Paramount Instruments",
      path: "/paramountInstruments/viewParamountInstruments",
      icon: "fa-solid fa-user",
      children: [],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "shipping",
    //   path: "/Dashboard",
    //   icon: "fa-solid fa-money-bill-1",
    //   children: [
    //     {
    //       name: "Carriers",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Shipping Rates",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Pickup Locations",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Shipping Orders",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Configuration",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Order Manage",
    //   path: "/Dashboard",
    //   icon: "ion-android-cart",
    //   children: [
    //     {
    //       name: "Total Order",
    //       path: "/Order/Total-Order",
    //       active: false,
    //     },
    //     {
    //       name: "Inhouse Orders",
    //       path: "/Order/Inhouse-Order",
    //       active: false,
    //     },
    //     {
    //       name: "delivery Process",
    //       path: "/Order/Delivery-Process",
    //       active: false,
    //     },
    //     {
    //       name: "Cancel Reason",
    //       path: "/Order/Cancle-Reason",
    //       active: false,
    //     },
    //     {
    //       name: "Track order Config",
    //       path: "/Order/Track-Order",
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Customer",
    //   path: "/Dashboard",
    //   icon: "fa-solid fa-users",
    //   children: [
    //     {
    //       name: "All Customer",
    //       path: "/Customer-list",
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Frontend CMS",
    //   path: "/Dashboard",
    //   icon: "fa-solid fa-user",
    //   children: [
    //     {
    //       name: "Banners",
    //       path: "/Banners",
    //       active: false,
    //     },
    //   ],
    // },

    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Appearance",
    //   path: "/Dashboard",
    //   icon: "ion-grid",
    //   children: [
    //     {
    //       name: "Themes",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Color Scheme",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Menu",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Header",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Dashboard Setup",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Dashboard Color",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //     {
    //       name: "Preloader Setting",
    //       path: "/Dashboard",
    //       active: false,
    //     },
    //   ],
    // },
    {
      isrotated: false,
      active: false,
      name: "Blogs",
      path: "/Dashboard",
      icon: "fa-solid fa-users",
      children: [
        {
          name: "View Blog",
          path: "/Blog/post",
          active: false,
        },
        // {
        //   name: "blog category",
        //   path: "/Blog/Category",
        //   active: false,
        // },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Lead",
      path: "/Lead",
      icon: "fa-solid fa-users",
      children: [],
      // children: [
      //   {
      //     name: "View Blog",
      //     path: "/Blog/post",
      //     active: false,
      //   },
      //   // {
      //   //   name: "blog category",
      //   //   path: "/Blog/Category",
      //   //   active: false,
      //   // },
      // ],
    },
    {
      isrotated: false,
      active: false,
      name: "Seo",
      path: "/Seo/View",
      icon: "ion-navicon-round",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Site-Map",
      path: "/Sitemap-data",
      icon: "ion-map",
      children: [],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Review",
    //   path: "/Dashboard",
    //   icon: "fa-solid fa-user",
    //   children: [
    //     {
    //       name: "Product Review",
    //       path: "/Review/Product-Review",
    //       active: false,
    //     },
    //     {
    //       name: "Company Review ",
    //       path: "/Review/Product-Review",
    //       active: false,
    //     },
    //     // {
    //     //   name: "Review Configuration",
    //     //   path: "/Review/Review-Configuration",
    //     //   active: false,
    //     // },
    //   ],
    // },
    {
      isrotated: false,
      active: false,
      name: "Get In Touch Enquiries",
      path: "/Dashboard",
      icon: "fa-solid fa-user",
      children: [
        {
          name: "View Enquiries",
          path: "/Contact-Mail",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Career",
      path: "/Dashboard",
      icon: "fa-solid fa-user",
      children: [
        {
          name: "View Career Form submissions",
          path: "/Career",
          active: false,
        },
      ],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Payment Gateways",
    //   path: "/Payment-Gateway",
    //   icon: "fa-solid fa-money-bill-1",
    //   children: [],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "GST/VAT/TAX Setup",
    //   path: "/GST-SETUP",
    //   icon: "ion-settings",
    //   children: [
    //     {
    //       name: "GST/VAT/TAX List",
    //       path: "/GST-SETUP",
    //       active: false,
    //     },
    //     {
    //       name: "Configuration",
    //       path: "/GST-Configuation",
    //       active: false,
    //     },
    //   ],
    // },
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div id="sidebar">
      <div className="main-logo">
        <img src={images.logo} style={{ height: "100%", width: "100%" }} alt="" />
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item.map((item, i) => {
          if (typeof array === "undefined" && item.children.length === 0) {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={item.path}
                  className={item.active ? "active" : ""}
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">{item.name}</p>
                </Link>
              </li>
            );
          } else {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={`#sidebar_item_children_${i}`}
                  className={
                    item.active || location === item.path ? "active" : ""
                  }
                  data-bs-toggle="collapse"
                  aria-expanded={item.active}
                  aria-controls={`sidebar_item_children_${i}`}
                  role="button"
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">
                    {item.name}
                    {item.isrotated ? (
                      <i className="ion-arrow-up-b pe-3"></i>
                    ) : (
                      <i className="ion-arrow-down-b pe-3"></i>
                    )}
                  </p>
                </Link>
                <ul
                  className="collapse"
                  id={`sidebar_item_children_${i}`}
                  data-bs-parent="#sidebarMenu"
                >
                  {item.children.map((child, index) => {
                    return (
                      <li key={`${child.name}_${index}`}>
                        <Link
                          to={child.path}
                          className={
                            child.active || location === child.path
                              ? "active"
                              : ""
                          }
                          onClick={() => childActive(index)}
                        >
                          {child.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default SideBar;
