import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteCity, getCity } from "../../services/City.service";
import { toastError } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import AddCity from "./AddCity";

export default function ViewCity() {
  const [cityArr, setCityArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");

  const getCities = async () => {
    try {
      const { data: res } = await getCity();
      if (res) {
        setCityArr(res.data);
        setMainArr(res.data);
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  useEffect(() => {
    getCities();
    return () => {
      setCityArr([]);
    };
  }, []);


  const [isEditActive, setIsEditActive] = useState(false);
  const [selected, setSelected] = useState({});

  const handleSetSelected = () => {
    setSelected({})
    setIsEditActive(false)
    getCities()
  }


  const deleteCityData = async (id) => {
    try {
      const { data: res } = await deleteCity(id);
      if (res) {
        getCities();
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };



  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "City NAME",
      selector: (row) => row?.name,
    },
    {
      name: "State NAME",
      selector: (row) => row?.stateObj?.name,
    },
    {
      name: "Country NAME",
      selector: (row) => row?.countryObj?.name,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            edit
            isRedirected={true}
            editPath="/generalData/city"
            deletePath="/generalData/city"
            onDeleteClick={(e) => {
              e.preventDefault();
              deleteCityData(row._id);
            }}
            onEditClick={(e) => {
              e.preventDefault();
              setIsEditActive(true)
              setSelected(row)
            }}
          />
          <AddModal ModalBox={ModalBox} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="min-content" />
        </>
      ),
    },
  ];


  const filterData = (value) => {
    let tempArr = mainArr;
    tempArr = tempArr.filter(el => (
      el.name.toLowerCase().includes(`${value}`.toLowerCase())
      || el?.stateObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
      || el?.countryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
    ))
    setCityArr([...tempArr])
    setQuery(value)
  }



  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} City</h5>
              </div>
              <DashboardBox>
                <AddCity handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                <h5 className="blue-1 m-0">City Lists</h5>
                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={cityArr} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
