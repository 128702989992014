import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
// import { deleteResource, getLead } from "../../services/resources.service";
import {  getLead,  deleteLeadById } from "../../services/Lead.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { url } from "../../services/url.service";



function Lead() {

  const [resourcesArr, setResourcesArr] = useState([]);
  const [displayResourceArr, setDisplayResourceArr] = useState([]);
  const [query, setQuery] = useState("");





  const handleDeleteCategory = async (id) => {
    try {
      let { data: res } = await deleteLeadById(id);
      if (res.success) {
        console.log(res.data)
        toastSuccess(res.message)
        getAllResources()
      }
    } catch (err) {
      toastError(err)
    }
  }


  const blog_columns = [
    {
      name: "SL",
      selector: (row, i) => i + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.firstName+row?.lastName,
      width: "20%",
    },
    
    {
      name: "Email",
      grow: 0,
      selector: (row) => row.email,
      width: "25%",
    },
    {
      name: "Company Name",
      grow: 0,
      selector: (row) => row.companyName,
      width: "20%",
    },
    {
      name: "Country Name",
      grow: 0,
      selector: (row) => row.countryName,
      width: "20%",
    },
   
    {
      name: "Action",
      cell: (row) => <ActionIcon
        remove
        onDeleteClick={(e) => {
          e.preventDefault()
          handleDeleteCategory(row._id)
        }}
        delete
        deletePath="/Lead"
        detailpath={`/Lead${row._id}`}
        isRedirected={true}
        Uniquekey={row.id} />,
      width: "15%",
    },
  ];



  const getAllResources = async () => {
    try {
      let { data: res } = await getLead()
      console.log(res ,"werr32r23rrtwerwer   ")
      if (res.success) {
        setResourcesArr(res.data)
        setDisplayResourceArr(res.data)
      }

    } catch (error) {
      console.error(error)
      toastError(error)
    }
  }

  useEffect(() => {
    getAllResources()
    return () => setResourcesArr([])
  }, [])


  const handleSearch = (value) => {
    setQuery(value)
    let tempArr = resourcesArr.filter(el =>
      `${el.heading}`.toLowerCase().includes(`${value}`.toLowerCase()) ||
      `${el.resourceType}`.toLowerCase().includes(`${value}`.toLowerCase())
    )
    setDisplayResourceArr([...tempArr])
  }




  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Lead List</h5>
                <div className="d-flex gap-3">
                  {/* <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW BLOG"
                    path="/Blog/post/create"
                  /> */}
                  <SearchBox query={query} handleSearch={handleSearch} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable columns={blog_columns} data={displayResourceArr} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Lead;
