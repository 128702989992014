import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue, updateSubCategories } from "../../services/subCategories.service";
import FileUpload from "../Utility/FileUpload";
import { url } from "../../services/url.service";
import { generateFilePath } from "../Utility/utils";
export default function AddSubCategory({ handleSetSelected, data, setDataIsAdded }) {

    const [menuCategoryId, setMenuCategoryId] = useState("");

    const [selectedOption, setSelectedOption] = useState({});
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [menuCategoriesArr, setmenuCategoriesArr] = useState([]);
    const [image, setImage] = useState("");
    const [altimage, setaltImage] = useState("");
    const [mobileimage, setmobileImage] = useState("");
    const [slug, setSlug] = useState("");
    const [brochureFile, setBrochureFile] = useState("");
    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                menuCategoryId,
                image,
                slug,
                description,
                brochureFile,
                altimage,
                mobileimage
            };
            let res
            if (isBeingEdited) {
                res = await updateSubCategories(data._id, obj)
            }
            else {
                res = await addSubCategoriesValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                setDataIsAdded(true)
                handleClearState()
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };


    const handleClearState = () => {
        setName("");
        setSlug("");
        setIsBeingEdited(false);
        setMenuCategoryId("")
        setDescription("")
        setBrochureFile("");
        handleSetSelected();
        setSelectedOption({});
    }



    const handleGetAllMenuCategories = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getmenuCategories();
            if (res.success) {
                console.log(res.data)
                setmenuCategoriesArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleAttributeValueChange = (e) => {
        console.log("event", e?.value)
        setMenuCategoryId(e.value);
        setSelectedOption(e);
        // setCountryId(e.target.value);
    };

    useEffect(() => {
        if (data && data.name && menuCategoriesArr && menuCategoriesArr.length > 0) {
            setName(data.name)
            setSlug(data.slug ? data.slug : "")
            setImage(data.image)
            setaltImage(data.altimage)
            setmobileImage(data.mobileimage)
            console.log(data.countryId, "daa")
            let menuCategoryObj = menuCategoriesArr.map(el => {
                let obj = {
                    ...el,
                    label: el.name,
                    value: el._id,
                }
                return obj
            }).find(el => el._id == data.menuCategoryId)
            setIsBeingEdited(true);
            console.log(menuCategoryObj, "countryObj");
            setMenuCategoryId(data.menuCategoryId);
            setBrochureFile(data.brochureFile);
            setSelectedOption(menuCategoryObj)
            setDescription(data.description ? data.description : "")
        }
    }, [data, menuCategoriesArr])




    useEffect(() => {
        handleGetAllMenuCategories()
    }, [])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Slug <span className="red">*</span>
                </label>
                <input value={slug} onChange={(event) => setSlug(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Description <span className="red">*</span>
                </label>
                <input value={description} onChange={(event) => setDescription(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">Menu Category</label>
                <Select defaultInputValue={menuCategoryId} value={selectedOption} onChange={handleAttributeValueChange} options={menuCategoriesArr && menuCategoriesArr.length > 0 ? menuCategoriesArr.map((el) => ({ ...el, label: el?.name, value: el?._id })) : []} />
            </div>

            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    image && image != "" && image.includes("base64") ?
                        <img src={image && image != "" ? image.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(image)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Mobile Image <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    mobileimage && mobileimage != "" && mobileimage.includes("base64") ?
                        <img src={mobileimage && mobileimage != "" ? mobileimage.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(mobileimage)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setmobileImage(e)} />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Alt Tag Image <span className="red">*</span>
                </label>
                <input value={altimage} onChange={(event) => setaltImage(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Brochure file <span className="red">*</span>
                </label>
                <br />
                <br />
                {
                    brochureFile && brochureFile != "" && brochureFile.includes("base64") ?
                        <img src={brochureFile && brochureFile != "" ? brochureFile.split("@@")[1] : ""} alt="" style={{ width: 100 }} />
                        :
                        <img src={generateFilePath(brochureFile)} style={{ width: 100 }} alt="" />
                }
                <br />
                <br />
                <FileUpload onFileChange={(e) => setBrochureFile(e)} />
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
