import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { getState } from "../../services/State.service";
import { addCityValue, updateCity } from "../../services/City.service";

export default function AddCity({ handleSetSelected, data }) {
  const [stateArr, setStateArr] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [name, setName] = useState("");
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const getStates = async () => {
    try {
      const { data: res } = await getState();
      if (res) {
        setStateArr(res.data);
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  const handleSubmit = async () => {
    try {
      let obj = {
        name,
        stateId: stateId,
        countryId: countryId
      };

      let res = {}
      if (isBeingEdited) {
        res = await updateCity(data._id, obj)
      }
      else {
        res = await addCityValue(obj);
      }

      if (res.data.message) {
        toastSuccess(res.data.message);
        handleClearState()
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };


  useEffect(() => {
    if (data && data.name && stateArr && stateArr.length > 0) {
      setName(data.name)
      console.log(data.countryId, "daa")
      let countryObj = stateArr.map(el => {
        let obj = {
          ...el,
          label: el.name,
          value: el._id,
        }
        return obj
      }).find(el => el._id == data.stateId)
      setIsBeingEdited(true)
      console.log(countryObj, "countryObj")
      setStateId(countryObj)
      setCountryId(data.countryId)
      setSelectedOption(countryObj)
    }
  }, [data, stateArr])

  const handleClearState = () => {
    setName("");
    setCountryId("");
    setIsBeingEdited(false);
    handleSetSelected();
    setStateId("");
    setSelectedOption({});
  }



  const handleStateChange = (e) => {
    // console.log(e, "event", e.target.value);
    setCountryId(e.countryObj._id);
    setSelectedOption(e)
    setStateId(e.value);
    // setSelectedStateId(e.target.value);
  };
  return (
    <form action="#" className="form row">
      <div className="col-12">
        <label className="blue-1 fs-12">
          Name <span className="red">*</span>
        </label>
        <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">State</label>
        <Select defaultInputValue={stateId} value={selectedOption} onChange={handleStateChange} options={stateArr && stateArr.length > 0 ? stateArr.map((el) => ({ ...el, label: el?.name, value: el?._id })) : []} />
      </div>

      <div className="col-12 mt-2">
        <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
      </div>
    </form>
  );
}
