import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { deleteSeo, getSeo } from '../../services/seo.service';
import ActionIcon from '../Utility/ActionIcon';
import { DashboardBox, DashboardTable } from '../Utility/DashboardBox';
import SearchBox from '../Utility/SearchBox';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';
import AddSeo from './AddSeo'

export default function ViewSeo() {
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [seoArr, setSeoArr] = useState([]);
    const [currentSelectedSeo, setCurrentSelectedSeo] = useState({});
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
            width: "4%"
        },
        {
            name: "Page Url",
            selector: (row) => row?.pageUrl,
            sortable: true,
        },
        {
            name: "Meta Image",
            cell: (row) => <img src={generateFilePath(row?.metaImage)} style={{ width: 200 }} alt="" />,
            sortable: true,
        },
        {
            name: "Meta Title",
            selector: (row) => row?.metaTitle,
            sortable: true,
        },
        {
            name: "Meta Keywords",
            selector: (row) => row?.metaKeywords,
            sortable: true,
        },
        {
            name: "Meta Description",
            selector: (row) => row?.metaDescription,
            sortable: true,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row?._id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteSeo(row?._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault();
                            setCurrentSelectedSeo(row)
                        }}
                    />
                </>
            ),
        },
    ];


    const handleGetSeoData = async () => {
        try {
            let { data: res } = await getSeo();
            if (res.success) {
                console.log(res.data)
                setSeoArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteSeo = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deleteSeo(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetSeoData()
            }
        } catch (err) {
            toastError(err)
        }
    }












    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            (el?.pageUrl && el?.pageUrl.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.metaTitle && el?.metaTitle.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.metaKeywords && el?.metaKeywords.toLowerCase().includes(`${value}`.toLowerCase()))
            || (el?.metaDescription && el?.metaDescription.toLowerCase().includes(`${value}`.toLowerCase()))
        ))
        setSeoArr([...tempArr])
        setQuery(value)
    }









    useEffect(() => {
        handleGetSeoData()
        return () => setSeoArr([])
    }, [dataIsAdded])


    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{currentSelectedSeo.pageUrl ? "Update" : "Add New"} SEO Tag</h5>
                            </div>
                            <DashboardBox>
                                <AddSeo setCurrentSelectedSeo={setCurrentSelectedSeo} currentSelectedSeo={currentSelectedSeo} setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">SEO Tags Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={seoArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
