
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/resources";

export const addResourceValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};
export const getResource = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};
export const getResourceById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getBlogById/${id}`);
};
export const deleteResource = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updateResource = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};