import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { addCountryValue, updateCountry } from "../../services/Country.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
export default function EditCountry({ data }) {


    const [name, setName] = useState("");

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
            };
            let { data: res } = await updateCountry(data._id, obj)
            if (res.success) {
                toastSuccess(res.message)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };



    useEffect(() => {
        if (data && data?.name) {
            setName(data?.name)
            console.log(data, "prop data 123")
        }
        // return setName("")
    }, [data])

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
            </div>
            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
