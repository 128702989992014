import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue } from "../../services/subCategories.service";
import { addOurServicesValue } from "../../services/ourServices.service";
import { getProductCategories } from "../../services/productCategories.service";
import FileUpload from "../Utility/FileUpload";
import { addClientImageValue } from "../../services/Clients.service";
import { addProductCategoryImageValue, updateProductCategoryImage } from "../../services/productCategoryClient.service";
export default function AddProductCategoryClient({ handleSetSelected, data, setDataIsAdded }) {

    const [productCategoriesArr, setProductCategoriesArr] = useState([]);
    const [productCategoryId, setProductCategoryId] = useState("");
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});



    const [logo1, setLogo1] = useState("");

    const handleSubmit = async () => {
        try {
            let obj = {
                image: logo1,
                productCategoryId: productCategoryId
            };

            let res
            if (isBeingEdited) {
                res = await updateProductCategoryImage(data._id, obj)
            }
            else {
                res = await addProductCategoryImageValue(obj)
            }
            if (res.data.success) {
                toastSuccess(res.data.message)
                handleClearState()
                setDataIsAdded(true)
            }
            console.log(res)
        } catch (err) {
            toastError(err)
        }
    };

    const handleGetAllProductCategories = async () => {
        try {
            let { data: res } = await getProductCategories();
            if (res.success) {
                setProductCategoriesArr(res.data);
                setDataIsAdded(false);
            }
        } catch (err) {
            toastError(err);
        }
    }


    useEffect(() => {
        if (data && data.productCategoryId && productCategoriesArr && productCategoriesArr.length > 0) {
            let productCategoryObj = productCategoriesArr.map(el => {
                let obj = {
                    ...el,
                    label: el.name,
                    value: el._id,
                }
                return obj
            }).find(el => el._id == data.productCategoryId);
            console.log(productCategoryObj, "productCategoryObj")
            setIsBeingEdited(true);
            setProductCategoryId(productCategoryObj._id)
            setSelectedOption(productCategoryObj);
        }
    }, [data, productCategoriesArr])

    const handleClearState = () => {
        setLogo1("");
        setProductCategoryId("");
        setIsBeingEdited(false);
        handleSetSelected();
        setSelectedOption({});
    }

    useEffect(() => {
        handleGetAllProductCategories();
    }, [])

    const handleChange = (Value) => {
        setProductCategoryId(Value._id)
        setSelectedOption(Value)
        // console.log(Value, "value")
    }



    const handleFileSet = (value) => {
        // console.log(value);
        setLogo1(value);
    };

    return (
        <form action="#" className="form row">
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Product Category<span className="red">*</span>
                </label>
                <Select defaultInputValue={productCategoryId} value={selectedOption} onChange={handleChange} options={productCategoriesArr && productCategoriesArr.length > 0 && productCategoriesArr.map((el, index) => ({ ...el, label: el.name, value: el._id }))} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>
            <div className="col-12">
                <label className="blue-1 fs-12">
                    Image 1<span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
                {/* <input onChange={(event) => setLogo(event.target.value)} type="file" accept="image/*" className="form-control" /> */}
            </div>

            <div className="col-12 mt-2">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
            </div>
        </form>
    );
}
