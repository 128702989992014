import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/menuCategory";

export const addMenuCategoriesValue = async (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getmenuCategories = async () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deletemenuCategories = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateMenuCategory = async (id, formData) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
