import Select from "react-select";
import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addMenuCategoriesValue, getmenuCategories } from "../../services/menuCategories.service";
import { addSubCategoriesValue } from "../../services/subCategories.service";
import { addOurServicesValue } from "../../services/ourServices.service";
import { getProductCategories } from "../../services/productCategories.service";
import FileUpload from "../Utility/FileUpload";
import { addClientImageValue } from "../../services/Clients.service";
import { addwebsiteSettingsValue, getwebsiteSettings } from "../../services/siteData.service";
export default function AddSiteDetails({ setDataIsAdded }) {
  const [installationWorldwide, setInstallationWorldwide] = useState("");
  const [labInstalled, setLabInstalled] = useState("");
  const [yearOfExperience, setYearOfExperience] = useState("");
  const [countryExport, setCountryExport] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [linkedInLink, setLinkedInLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [websiteData, setWebsiteData] = useState({});

  const [youtubeLink, setYoutubeLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");



  const [features, setFeatures] = useState("");
  const [overview, setOverview] = useState("");
  const [review, setReview] = useState("");
  const [productVideo, setProductVideo] = useState("");
  const [technicalSpecifications, setTechnicalSpecifications] = useState("");
  const [relatedStandards, setRelatedStandards] = useState("");
  const [endUse, setEndUse] = useState("");
  const handleSubmit = async () => {
    try {
      let obj = {
        installationWorldwide,
        labInstalled,
        yearOfExperience,
        countryExport,
        phone1,
        phone2,
        email,
        address,
        facebookLink,
        linkedInLink,
        twitterLink,
        youtubeLink,
        instagramLink,
        features,
        overview,
        review,
        productVideo,
        technicalSpecifications,
        relatedStandards,
        endUse,
      };
      let { data: res } = await addwebsiteSettingsValue(obj);
      if (res.success) {
        toastSuccess(res.message);
        setDataIsAdded(true);
      }
      console.log(res);
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetWebsiteSettings = async () => {
    try {
      let { data: res } = await getwebsiteSettings();
      if (res.data) {
        res.data = res.data[0];
        console.log(res.data, "websiteData");
        setInstallationWorldwide(res?.data?.installationWorldwide);
        setLabInstalled(res?.data?.labInstalled);
        setYearOfExperience(res?.data?.yearOfExperience);
        setCountryExport(res?.data?.countryExport);
        setPhone1(res?.data?.phone1);
        setPhone2(res?.data?.phone2);
        setAddress(res?.data?.address);



        setYoutubeLink(res?.data?.youtubeLink);
        setInstagramLink(res?.data?.instagramLink);





        setEmail(res?.data?.email);
        setFacebookLink(res?.data?.facebookLink);
        setLinkedInLink(res?.data?.linkedInLink);
        setTwitterLink(res?.data?.twitterLink);
        setFeatures(res?.data?.features);
        setOverview(res?.data?.overview);
        setReview(res?.data?.review);
        setRelatedStandards(res?.data?.relatedStandards);
        setTechnicalSpecifications(res?.data?.technicalSpecifications);
        setProductVideo(res?.data?.productVideo);
        setEndUse(res?.data?.endUse);
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };

  useEffect(() => {
    handleGetWebsiteSettings();
    return () => {
      setWebsiteData({});
    };
  }, []);

  return (
    <form action="#" className="form row">
      <div className="col-12">
        <label className="blue-1 fs-12">
          Installation Worldwide <span className="red">*</span>
        </label>
        <input onChange={(event) => setInstallationWorldwide(event.target.value)} value={installationWorldwide} type="number" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Lab Installed <span className="red">*</span>
        </label>
        <input onChange={(event) => setLabInstalled(event.target.value)} value={labInstalled} type="number" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Year Of Experience <span className="red">*</span>
        </label>
        <input onChange={(event) => setYearOfExperience(event.target.value)} value={yearOfExperience} type="number" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Country Export <span className="red">*</span>
        </label>
        <input onChange={(event) => setCountryExport(event.target.value)} value={countryExport} type="number" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Phone Number 1 <span className="red">*</span>
        </label>
        <input onChange={(event) => setPhone1(event.target.value)} value={phone1} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Phone Number 2 <span className="red">*</span>
        </label>
        <input onChange={(event) => setPhone2(event.target.value)} value={phone2} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Email Id <span className="red">*</span>
        </label>
        <input onChange={(event) => setEmail(event.target.value)} value={email} type="email" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Address <span className="red">*</span>
        </label>
        <textarea onChange={(event) => setAddress(event.target.value)} value={address} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Facebook Link <span className="red">*</span>
        </label>
        <input onChange={(event) => setFacebookLink(event.target.value)} value={facebookLink} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          LinkedIn Link <span className="red">*</span>
        </label>
        <input onChange={(event) => setLinkedInLink(event.target.value)} value={linkedInLink} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Twitter Link <span className="red">*</span>
        </label>
        <input onChange={(event) => setTwitterLink(event.target.value)} value={twitterLink} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">

          Youtube Link <span className="red">*</span>
        </label>
        <input onChange={(event) => setYoutubeLink(event.target.value)} value={youtubeLink} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Instagram Link <span className="red">*</span>
        </label>
        <input onChange={(event) => setInstagramLink(event.target.value)} value={instagramLink} type="text" className="form-control" />
      </div>

      <div className="col-12">
        <label className="blue-1 fs-12">
          Features <span className="red">*</span>
        </label>
        <textarea onChange={(event) => setFeatures(event.target.value)} value={features} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Overview <span className="red">*</span>
        </label>
        <input onChange={(event) => setOverview(event.target.value)} value={overview} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Review <span className="red">*</span>
        </label>
        <input onChange={(event) => setReview(event.target.value)} value={review} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Product Video <span className="red">*</span>
        </label>
        <input onChange={(event) => setProductVideo(event.target.value)} value={productVideo} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Related Standards <span className="red">*</span>
        </label>
        <input onChange={(event) => setRelatedStandards(event.target.value)} value={relatedStandards} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          End Use <span className="red">*</span>
        </label>
        <input onChange={(event) => setEndUse(event.target.value)} value={endUse} type="text" className="form-control" />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">
          Technical Specifications <span className="red">*</span>
        </label>
        <input onChange={(event) => setTechnicalSpecifications(event.target.value)} value={technicalSpecifications} type="text" className="form-control" />
      </div>

      <div className="col-12 mt-2">
        <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
      </div>
    </form>
  );
}
