import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteProductCategories, getProductCategories } from "../../services/productCategories.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddProductCategory from "./AddProductCategory";
import { generateFilePath } from "../Utility/utils";
export default function ProductCategories() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [productCategoriesArr, setProductCategoriesArr] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selected, setSelected] = useState({});
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");

    const handleGetAllProductCategories = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getProductCategories();
            if (res.success) {
                // console.log(res.data)
                setProductCategoriesArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }
    const handleSetSelected = () => {
        setSelected({})
        setIsEditActive(false)
        handleGetAllProductCategories()
    }



    const handleDeleteProductCategory = async (id) => {
        try {
            // console.log("asd a")
            let { data: res } = await deleteProductCategories(id);
            if (res.success) {
                console.log(res.data)
                toastSuccess(res.message)
                handleGetAllProductCategories()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Product Category Name",
            selector: (row) => row?.name,
        },
        {
            name: "Description",
            selector: (row) => row?.description,
        },
        {
            name: "Sub Category Name",
            selector: (row) => row?.subCategoryObj?.name,
        },
        {
            name: "Menu Category Name",
            selector: (row) => row?.menuCategoryObj?.name,
        },
        {
            name: "Image",
            cell: (row) => <img src={generateFilePath(row.image)} style={{ height: 50, width: 50 }} />,
        },
        {
            name: "Banner Image",
            cell: (row) => <img src={generateFilePath(row.bannerImage)} style={{ height: 50, width: 50 }} />,
        },
        {
            name: "Bottom Image",
            cell: (row) => <img src={generateFilePath(row?.bottomImage)} style={{ height: 50, width: 50 }} />,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteProductCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setIsEditActive(true)
                            setSelected(row)
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];




    useEffect(() => {
        handleGetAllProductCategories()
        return () => setProductCategoriesArr([])
    }, [dataIsAdded])


    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            el.name && el?.name?.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.description.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.menuCategoryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.subCategoryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
        ))
        setProductCategoriesArr([...tempArr])
        setQuery(value)
    }



    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} Product Category</h5>
                            </div>
                            <DashboardBox>
                                <AddProductCategory handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Product Category Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productCategoriesArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
