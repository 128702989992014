import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getCountry, deleteCountry } from "../../services/Country.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal, EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError } from "../Utility/ToastUtils";
import AddCountry from "./AddCountry";

export default function ViewCountry() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [countryArr, setCountryArr] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");
    const handleGetAllCountries = async () => {
        try {
            console.log("asd a")
            let { data: res } = await getCountry();
            if (res.success) {
                console.log(res.data)
                setCountryArr(res.data);
                setMainArr(res.data);
            }
        } catch (err) {
            toastError(err)
        }
    }


    const handleDeleteCategory = async (id) => {
        try {
            console.log("asd a")
            let { data: res } = await deleteCountry(id);
            if (res.success) {
                console.log(res.data)
                handleGetAllCountries()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Country Name",
            selector: (row) => row.name,
        },
        {
            name: "Action",
            minWidth: "210px",
            maxWidth: "211px",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/country"
                        deletePath="/generalData/country"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            // setModalBox(true)
                            setIsEditActive(true)
                            // setModalType("editCountry");
                            // setModalName("Edit Country");
                            setSelectedCountry(row)
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];


    const handleSetSelectedCountry = () => {
        setSelectedCountry({})
        handleGetAllCountries()
    }

    useEffect(() => {
        handleGetAllCountries()
        return () => setCountryArr([])
    }, [])



    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => el.name.toLowerCase().includes(`${value}`.toLowerCase()))
        setCountryArr([...tempArr])
        setQuery(value)
    }

    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} Country</h5>
                            </div>
                            <DashboardBox>
                                <AddCountry handleSetSelectedCountry={handleSetSelectedCountry} data={isEditActive ? selectedCountry : {}} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Country Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={countryArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
