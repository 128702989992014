import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { deleteProductCategories, getProductCategories } from "../../services/productCategories.service";
import { deleteSiteMapData, getSiteMapData } from "../../services/SitemapData.service";
import { url } from "../../services/url.service";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import AddSiteMapData from "./AddSiteMapData";
export default function SiteMapData() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [siteMapDataArr, setProductCategoriesArr] = useState([]);
    const [isEditActive, setIsEditActive] = useState(false);
    const [selected, setSelected] = useState({});
    const [mainArr, setMainArr] = useState([]);
    const [query, setQuery] = useState("");




    const handleGetAllProductCategories = async () => {
        try {
            let { data: res } = await getSiteMapData();
            if (res.success) {
                setProductCategoriesArr(res.data);
                setMainArr(res.data);
                setDataIsAdded(false)
            }
        } catch (err) {
            toastError(err)
        }
    }
    const handleSetSelected = () => {
        setSelected({})
        setIsEditActive(false)
        handleGetAllProductCategories()
    }



    const handleDeleteProductCategory = async (id) => {
        try {
            let { data: res } = await deleteSiteMapData(id);
            if (res.success) {
                toastSuccess(res.message)
                handleGetAllProductCategories()
            }
        } catch (err) {
            toastError(err)
        }
    }



    const site_map_data_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
            width: "4%"
        },
        {
            name: "Url",
            selector: (row) => row?.url,
            width: "34%"
        },
        {
            name: "Change Frequency",
            selector: (row) => row?.changefreq,
            width: "17%"
        },
        {
            name: "Priority",
            selector: (row) => row?.priority,
            width: "14%"
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row._id}
                        remove
                        edit
                        isRedirected={true}
                        editPath="/generalData/SubCategories"
                        deletePath="/generalData/SubCategories"
                        onDeleteClick={(e) => {
                            e.preventDefault()
                            handleDeleteProductCategory(row._id)
                        }}
                        onEditClick={(e) => {
                            e.preventDefault()
                            setIsEditActive(true)
                            setSelected(row)
                        }}
                    />
                    <EditModal ModalBox={ModalBox} data={row} setModalBox={setModalBox} name={ModalName} ModalType={ModalType} width="max-content" />
                </>
            ),
        },
    ];




    useEffect(() => {
        handleGetAllProductCategories()
        return () => setProductCategoriesArr([])
    }, [dataIsAdded])


    const filterData = (value) => {
        let tempArr = mainArr;
        tempArr = tempArr.filter(el => (
            el.name && el?.name?.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.description.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.menuCategoryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
            || el?.subCategoryObj?.name.toLowerCase().includes(`${value}`.toLowerCase())
        ))
        setProductCategoriesArr([...tempArr])
        setQuery(value)
    }



    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">{isEditActive ? "Edit" : "Add New"} Site-Map data</h5>
                            </div>
                            <DashboardBox>
                                <AddSiteMapData handleSetSelected={handleSetSelected} data={isEditActive ? selected : {}} setDataIsAdded={setDataIsAdded} />
                            </DashboardBox>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Site-Map data Lists</h5>
                                <SearchBox query={query} handleSearch={filterData} extraClass="bg-white" />
                            </div>
                            <DashboardTable>
                                <DataTable columns={site_map_data_columns} data={siteMapDataArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
